import pogo from "../images/pogo.png";
import "../footer/footer.css";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { lineHeight } from "@mui/system";

export default function Footer() {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

  return (
    <footer className="container" style={{ height: "88px" }}>
      {" "}
      <div className="footerLogo">
        <a className="footerDelivers" href="#">
          {t("HEADER_LOGO")}
        </a>
        <img className="footer_logo_pogo" src={pogo} alt="pogo" />
      </div>
      <div
        className="contactUs"
        style={{ lineHeight: isMobile ? "42px" : "10px" }}
      >
        {t("CONTACT_US")}
        <a href="tel:039786309">039786309</a>
      </div>
    </footer>
  );
}
