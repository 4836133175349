import {
    TextField,
    CssBaseline,
    autocompleteClasses,
    IconButton,
    InputAdornment,
  } from "@mui/material";
  import CancelIcon from "@mui/icons-material/Cancel";
  import ClearIcon from "@mui/icons-material/Clear";
  import SearchIcon from "@mui/icons-material/Search";
  import React, { useEffect, useState, useRef, useContext } from "react";
  import { ThemeProvider, createTheme } from "@mui/material/styles";
  import rtlPlugin from "stylis-plugin-rtl";
  import { CacheProvider } from "@emotion/react";
  import createCache from "@emotion/cache";
  import { useMediaQuery } from "react-responsive";
  import Button from "@mui/material/Button";
  import placeholder from "../images/placeholder.png";
  import "../detailsPackage/reactAutoComplete.css";
  import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
  import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from "react-places-autocomplete";
  import { useTranslation } from "react-i18next";
  import { height } from "@mui/system";
  
  import { useLocation, useParams } from "react-router-dom";
  import { DataContext } from "../../../dataContext";
  import { isDisabled } from "@testing-library/user-event/dist/utils";
  
  export default function SearchLocation({
    address,
    selectFuncCityName,
    selectFuncStreetName,
    selectFuncStreetNum,
    errorAutoComplet
  }) {
    const {
      setErrorAutoComplet,
      ref,
      businessCostumerConnect,
      showSteps,
      setInvalidAddresspopUpMessage,
      //setExitaddress,
    } = useContext(DataContext);
    const { token } = useParams();
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 650px)" });
    const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

    
    const ltrTheme =
    document.documentElement.dir === "rtl"
      ? createTheme({ direction: "rtl" })
      : createTheme({ direction: "ltr" });
    const { t, i18n } = useTranslation();
    
    const selectFuncCurrentStreetName = (e) => {
      address.streetName = e.target.value;
      selectFuncStreetName(e); 
    };
  
    const selectFuncCurrentStreetNum = (e) => {
      address.streetNum = e.target.value;
      selectFuncStreetNum(e);
    };
  
    const selectFuncCurrentCityName = (e) => {
      address.cityName = e.target.value;
      selectFuncCityName(e);
    };

    return (
      <div className="costumerDetails_width"> 
      <TextField
            autoComplete="off"
            error={!!errorAutoComplet}
            helperText={
              errorAutoComplet
            }
            onChange={selectFuncCurrentStreetNum}
            value={address.streetNum && address.streetNum != "" ? address.streetNum : ""}
            label={t("ADD_DETAILS_WINDOW_INPUT_HOUSE_NUMBER")}
            id="outlined-size-small"
            sx={{
              width: "24%",
              height: "81px",
              marginRight: "5px",
              "& .MuiOutlinedInput-root": {
                fontSize: "16px",
                fontFamily: "Heebo",

                "& fieldset": {
                  direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                },
              },
            }}
            inputProps={{
              // readOnly: !address.streetName || address.streetNum,
              style: {
                direction:
                  document.documentElement.dir === "ltr"
                    ? "rtl"
                    : "ltr",
              },
            }}
            InputLabelProps={{
              style: {
                margin: "auto",
                display: "flex",
                fontFamily: "Heebo",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "25px",
                textAlign: "right",
                color: "#0D152E",
              },

              shrink: true,
            }}
          />{" "}
          <TextField
            autoComplete="off"
            onChange={selectFuncCurrentStreetName}
            value={address.streetName && address.streetName != "" ? address.streetName : ""}
            label={t("ADD_DETAILS_WINDOW_INPUT_STREET")}
            id="outlined-size-small"
            sx={{
              width: "24%",
              height: "81px",
              marginRight: "5px",
              "& .MuiOutlinedInput-root": {
                fontSize: "16px",
                fontFamily: "Heebo",

                "& fieldset": {
                  direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                },
              },
            }}
            inputProps={{
              // readOnly: !address.streetName || address.streetNum,
              style: {
                direction:
                  ltrTheme.direction === "ltr" &&
                  document.documentElement.dir === "ltr"
                    ? "rtl"
                    : "ltr",
              },
            }}
            InputLabelProps={{
              style: {
                margin: "auto",
                display: "flex",
                fontFamily: "Heebo",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "25px",
                textAlign: "right",
                color: "#0D152E",
              },

              shrink: true,
            }}
          />{" "}
          <TextField
            autoComplete="off"
            onChange={selectFuncCurrentCityName}
            value={address.cityName && address.cityName != "" ? address.cityName : ""}
            label={t("ADD_DETAILS_WINDOW_INPUT_CITY")}
            id="outlined-size-small"
            sx={{
              width: "24%",
              height: "81px",
              marginRight: "5px",
              "& .MuiOutlinedInput-root": {
                fontSize: "16px",
                fontFamily: "Heebo",

                "& fieldset": {
                  direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                },
              },
            }}
            inputProps={{
              // readOnly: !address.streetName || address.streetNum,
              style: {
                direction:
                  ltrTheme.direction === "ltr" &&
                  document.documentElement.dir === "ltr"
                    ? "rtl"
                    : "ltr",
              },
            }}
            InputLabelProps={{
              style: {
                margin: "auto",
                display: "flex",
                fontFamily: "Heebo",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "25px",
                textAlign: "right",
                color: "#0D152E",
              },

              shrink: true,
            }}
          />{" "}  
      </div>
    );
  }
  