import {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
  useStyles,
} from "react";
import { useTranslation } from "react-i18next";
import {
  TextField,
  CssBaseline,
  StepContext,
  Dialog,
  Alert,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useMediaQuery } from "react-responsive";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import Slider from "@mui/material/Slider";
import "./stepsNumCss/sliderPrice.css";
import InputAdornment from "@mui/material/InputAdornment";
import distance from "../../../images/distance.png";
import numDestination from "../../../images/numDestination.png";
import { DataContext } from "../../../../../dataContext";
import { useParams } from "react-router-dom";
import axios from "axios";
import { updateServer } from "../stepsNum/updateServer";
import _ from "lodash";
import time2 from "../../../images/time.png";

export default function SliderPrice({}) {
  const url = `pogo_connect_api/server/web/index.php/tracks/post_new_track`;
  //const url = `proj/pogo_connect/lw_pogo_connect/basic/server/web/tracks/post_new_track`;

  const {
    val,
    type,
    setType,
    validToken,
    setValidToken,
    setVal,
    setGetDataFromServer,
    getDataFromServer,
    checkboxValue,
    exitAddress,
    time,
    detailsOfPackage,
    setDetailsOfPackage,
    nameAndPhone,
    typeShippment,
    textArea,
    setTextArea,
    sumitCusId,
    setSumitCusId,
    paymentMethodId,
    setPaymentMethodId,
    setErrorsCreditCard,
    setMoveToDetailsOfCreditCard,
    testUser
  } = useContext(DataContext);

  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 650px)" });
  const { t, i18n } = useTranslation();
  const [colors, setColors] = useState("red");
  const [text, setText] = useState("");
  const [minutesToDateTime, setMinutesToDateTime] = useState("");
  const [meterToKm, setMeterToKm] = useState("");
  const [price, setPrice] = useState(0);
  const [optionPickUpTime, setOptionPickUpTime] = useState("1");
  const [optionDDlineTime, setOptionDDlineTime] = useState("3");
  const ref = useRef(null);
  const clone_price_min = Object.assign({}, getDataFromServer);
  const max = clone_price_min.price_min * 3;
  const { token } = useParams();


  useEffect(() => {
    time.ddLine = optionDDlineTime;
    time.pickUpTime = optionPickUpTime;
  }, []);

  useEffect(() => {
    const four = (clone_price_min.price_min * 3) / 4;

    if (val < four * 2) {
      setColors("#FF0000");
      setText(t("STEPS_NUM_3_HEADER_LINE_4"));
    }

    if (val > four * 3) {
      setColors("#FFB26B");
      setText(t("STEPS_NUM_3_HEADER_LINE_5"));
    }
    if (val > (four * 7) / 2) {
      setColors("#5BDF60");
      setText(t("STEPS_NUM_3_HEADER_LINE_6"));
    }
  }, [val]);
  let m;
  let km;
  // old: Before changing a date to a selection list
  //const arrayPickUpTime = time?.pickUpTime?.split("T");
  //const arrayDdLine = time?.ddLine?.split("T");

  //const start_time = [arrayPickUpTime[0], arrayPickUpTime[1]]?.join(" ");
  //const end_time = [arrayDdLine[0], arrayDdLine[1]]?.join(" ");
  console.log(type);
  const start_time = time?.pickUpTime ? time?.pickUpTime : "1";
  const end_time = time?.ddLine ? time.ddLine : "1";

  const ordersAddress = detailsOfPackage.map((order, i) => {
    return {
      address: {
        lat: order.lat.toString(),
        lng: order.lng.toString(),
        cityName: order.cityName,
        streetName: order.streetName,
        streetNum: order.streetNum,
      },
      order_price: order.payForStation,
      note: order.comment,
      contactName: order.contactName,
      contactPhone: order.contactPhone,
      interactionType: order.pickOrDrop,
      apartment_number: order.apartment_number,
      floor: order.floor,
      entrance: order.entrance
    };
  });
  const data = {
    exit_address: {
      cityName: exitAddress?.cityName || "",
      streetName: exitAddress?.streetName || "",
      streetNum: exitAddress?.streetNum || "",
      lat: exitAddress?.lat || "",
      lng: exitAddress?.lng || "",
    },
    type: type,
    start_time: start_time,
    end_time: end_time,
    orders: ordersAddress,
    price: getDataFromServer.new_price ? getDataFromServer.new_price : "",
    currency_symbol: "₪",
    customer_name: nameAndPhone?.fullName,
    phone: nameAndPhone?.phoneNumber,
    erea_code: nameAndPhone?.erea_code,
    driver_app: "pogo connect",
    track_id: getDataFromServer?.track_id ? getDataFromServer.track_id : "",
    vehicles_car: checkboxValue ? checkboxValue?.CarChecked : "1",
    vehicles_truck: checkboxValue ? checkboxValue?.TruckChecked : "1",
    vehicles_motorcycle: checkboxValue ? checkboxValue?.MotorcycleChecked : "1",
  };

  const fetchData = async () => {
    var result = null;
    // getDataFromServer.isLoading = "start";
    // setGetDataFromServer({ ...getDataFromServer });
    try {
      if(validToken){
        result = await axios.post(
          "/pogo_connect_api/server/web/index.php/customers/post_new_track",
          data,
          {
            headers: {
              Authorization: `Bearer ${validToken?.access_token}`,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        );
      }else{
        result = await axios.post(url, data, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        });
      }

      if (result && result.status !== 200) {
        getDataFromServer.isLoading = "error";
        setGetDataFromServer({ ...getDataFromServer });
      } else {
        setVal(Math.round(result.data.price_min));
        setPrice(Math.round(result.data.price_min));

        getDataFromServer.track_id = result.data.track_id;
        getDataFromServer.new_price = null;
        getDataFromServer.price_min = Math.round(result.data.price_min * 1);
        getDataFromServer.track_minutes = result.data.track_minutes;
        getDataFromServer.track_distance = result.data.track_distance;
        getDataFromServer.count_orders = result.data.count_orders;
        getDataFromServer.currency_symbol = result.data.currency_symbol;
        getDataFromServer.isLoading = false;

        setGetDataFromServer({ ...getDataFromServer });
        time_convert(getDataFromServer?.track_minutes);
        m = getDataFromServer?.track_distance;
        // km = Math.round(m / 100) / 10;
        //km = (m / 1000).toFixed(2);
        km = m.toFixed(2);
        setMeterToKm(km);
      }
    } catch (error) {
      getDataFromServer.isLoading = "error";
      setGetDataFromServer({ ...getDataFromServer });
      if (error && error.response && error.response.status == 401) {
        localStorage.removeItem("access_token_client");
        setValidToken(false);
      }
    }
  };

  function time_convert(num) {
    var hours = Math.floor(num / 60);
    var minutes = num % 60;
    // console.log(minutes);

    setMinutesToDateTime(hours + ":" + Math.round(minutes));
  }
  const switchUpdateOrNew = async () => {
    let cloneResultData;
    if (!getDataFromServer.track_id && !token) {
      cloneResultData = _.cloneDeep(data);
    }

    if (exitAddress && detailsOfPackage.length) {
      if (getDataFromServer.track_id || token) {
        if (cloneResultData !== data || token) {
          const updateBeforeConfirmingPrice = true;
          const result = await updateServer({
            checkboxValue,
            detailsOfPackage,
            getDataFromServer,
            setGetDataFromServer,
            exitAddress,
            nameAndPhone,
            time,
            type,
            token,
            validToken,
            setValidToken,
            sumitCusId,
            setSumitCusId,
            paymentMethodId,
            setPaymentMethodId,
            setErrorsCreditCard,
            setMoveToDetailsOfCreditCard,
            testUser,
            updateBeforeConfirmingPrice
          });
          if (result === "ok") {
            let p = Math.round(getDataFromServer?.price_min);
            setVal(p);
            setPrice(p);
            time_convert(getDataFromServer?.track_minutes);
            m = getDataFromServer?.track_distance;
            km = m.toFixed(2);
            setMeterToKm(km);
          }
        }
        {
          return;
        }
      } else {
        fetchData();
      }
    }
  };

  useEffect(() => {
    switchUpdateOrNew();
  }, []);

  const InputLabelProps = {
    textAlign: "right",
    fontFamily: "Heebo",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#4F5976",
  };

  const themeRtl = createTheme({
    direction: "rtl",
  });

  const themeLtr = createTheme({
    direction: "ltr",
  });

  const cacheLtr = createCache({
    key: "muiltr",
  });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });
  const ltrTheme =
    document.documentElement.dir === "rtl"
      ? createTheme({ direction: "rtl" })
      : createTheme({ direction: "ltr" });

  const widthSliderVal = () => {
    if (isTabletOrMobile) {
      return "100%";
    }
    if (isMobile) {
      return "100%";
    }
    return "545px";
  };

  return (
    <div style={{ position: "relative" }} className="infoSteps">
      <div style={{ width: "100%" }}>
        <div
          className="headFlex_slider"
          style={{ marginBottom: isTabletOrMobile && "20px" }}
        >
          <h2 className="h1Step" style={{ lineHeight: isMobile && "34px" }}>
            {t("STEPS_NUM_3_HEADER_LINE_1")}
          </h2>
          <p className="pStep2" style={{ whiteSpace: !isMobile && "nowrap" }}>
            {t("STEPS_NUM_3_HEADER_LINE_2")}
          </p>
        </div>

        <div className="slidecontainer">
          <div className="track_time_results">
            <div className="track_time_results_flex">
              <div className="box_result_data">
                {" "}
                <div className="flexHead_data_result">
                  <span className="track_time_results_span">
                    {t("STEPS_NUM_3_LINE_7")}
                  </span>
                  <img className="icon_data_result" src={time2} alt="time" />
                </div>
                <span className="result_data_bold">
                  {" "}
                  {getDataFromServer?.count_orders}
                </span>
              </div>

              <div className="box_result_data">
                <div className="flexHead_data_result">
                  <span className="track_time_results_span">
                    {t("STEPS_NUM_3_LINE_6")}
                  </span>
                  <img
                    className="icon_data_result"
                    src={distance}
                    alt="distance"
                  />
                </div>
                <span className="result_data_bold">{`${meterToKm}km`}</span>
                {/* <span className="result_data_bold">{`${getDataFromServer?.track_distance.toFixed(
                  2
                )}km`}</span> */}
              </div>

              <div className="box_result_data">
                <div className="flexHead_data_result">
                  <span className="track_time_results_span">
                    {t("STEPS_NUM_3_LINE_5")}
                  </span>
                  <img
                    className="icon_data_result"
                    src={numDestination}
                    alt="numDestination"
                  />
                </div>
                <span className="result_data_bold">{minutesToDateTime}</span>
              </div>
            </div>
          </div>
          <div
            className="margin_slider_val"
            style={{
              marginBottom: isTabletOrMobile ? "20px" : "24px",
              width: widthSliderVal(),
            }}
          >
            <div
              style={{
                height: isMobile ? "154px" : "124px",
                width: isMobile && "100%",
                margin: isMobile && "auto",
              }}
            >
              <CacheProvider
                value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
              >
                <ThemeProvider
                  theme={ltrTheme.direction === "ltr" ? themeRtl : themeLtr}
                >
                  <CssBaseline />
                  <TextField
                    autoComplete="off"
                    inputRef={ref}
                    className="fontBolder"
                    label={t("STEPS_NUM_3_HEADER_LINE_3")}
                    id="outlined-size-small"
                    type="number"
                    sx={{
                      position: "relative",
                      width: isTabletOrMobile ? "100%" : "545px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          padding: isMobile ? "0 5rem 0 5rem" : "0 11px",
                          textAlign: isMobile ? "center" : "left",
                          fontFamily: "Heebo",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "15px",
                          lineHeight: "22px",
                          color: "#4F5976",
                          // padding: isMobile ? "0 123px" : "11px"
                          "& legend": {
                            height: isMobile ? "20px" : "11px",
                            "& span": { opacity: isMobile && "1" },
                          },
                        },
                        width: "100%",
                        direction:
                          document.documentElement.dir === "ltr"
                            ? "ltr"
                            : "rtl",
                      },
                      "& .MuiOutlinedInput-input": {
                        textAlign: isMobile && "end",
                        fontFamily: "Heebo",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "44px",
                        fontSize: "30px",
                        cursor: "pointer",
                        direction:
                          document.documentElement.dir === "ltr"
                            ? "ltr"
                            : "inherit",
                        width: isMobile ? "100%" : "auto",
                        color: "#0D152E",
                      },
                    }}
                    // value={val ? val : clone_price_min.price_min}

                    //defaultValue={val}
                    //onChange={(e) => setVal(e.target.value * 1)}
                    onChange={(e) => {
                      setPrice(e.target.value * 1);
                      // detailsOfPackage.pay_for_station = e.target.value * 1;
                      // setDetailsOfPackage([...detailsOfPackage]);
                      getDataFromServer.new_price = e.target.value * 1;
                      setGetDataFromServer({ ...getDataFromServer });
                    }}
                    // onChange={(e) =>
                    //   e.target.value > val
                    //     ? setVal(e.target.value * 1)
                    //     : setVal(val)
                    // }
                    inputProps={{
                      readOnly: true,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      min: clone_price_min.price_min,
                      max: max,
                      step: 1,
                      //value: clone_price_min.price_min,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            position: "relative",
                            width: isMobile ? "80%" : "6%",
                            direction:
                              document.documentElement.dir === "ltr"
                                ? "ltr"
                                : "inherit",

                            "& .MuiTypography-root": {
                              width: isMobile && "100%",
                              height: "34px",
                              fontFamily: "Arial",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "30px",
                              lineHeight: "34px",
                              color: "#7A7789",
                            },
                            "& fil": {},
                          }}
                        >
                          ₪
                        </InputAdornment>
                      ),
                      style: {
                        flexDirection:
                          isMobile && document.documentElement.dir === "ltr"
                            ? "row"
                            : "row-reverse",
                        justifyContent: !isMobile && "space-between",
                        height: "105px",
                        direction:
                          document.documentElement.dir === "ltr" && isMobile
                            ? "ltr"
                            : document.documentElement.dir === "ltr" &&
                              !isMobile
                            ? ""
                            : "ltr",
                      },
                    }}
                    InputLabelProps={{
                      style: !isMobile
                        ? InputLabelProps
                        : { visibility: "hidden" },
                      shrink: true,
                    }}
                    //value={val}
                    value={price}
                  />
                </ThemeProvider>
              </CacheProvider>
            </div>

            <CacheProvider
              value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
            >
              <ThemeProvider
                theme={ltrTheme.direction === "ltr" ? themeRtl : themeLtr}
              >
                <div
                  style={{
                    width: isMobile && "100%",
                    margin: isMobile && "0 auto",
                    marginRight: !isMobile && "14px",
                    paddingRight: isMobile && "2rem",
                  }}
                >
                  <Slider
                    sx={{
                      color: colors,
                      "& .MuiSlider-thumb": {
                        color: "583DFF",
                      },
                    }}
                    // defaultValue={clone_price_min.price_min *1}
                    min={clone_price_min.price_min * 1}
                    max={clone_price_min.price_min * 3}
                    value={price}
                    // onChange={(e) => {
                    //   setVal(e.target.value * 1);
                    // }}
                    // onChange={(e) => {
                    //   setPrice(e.target.value * 1);
                    // }}
                    onChange={(e) => {
                      setPrice(e.target.value * 1);
                      getDataFromServer.new_price = e.target.value * 1;
                      setGetDataFromServer({ ...getDataFromServer });

                      // detailsOfPackage.pay_for_station = e.target.value * 1;
                      // setDetailsOfPackage([...detailsOfPackage]);
                    }}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                  />

                  <label className="label_slider">{text}</label>
                </div>
              </ThemeProvider>
            </CacheProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
