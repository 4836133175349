
import { DataContext } from "./dataContext";
import { Navigate, Outlet } from "react-router-dom";
import { useContext, useEffect, } from "react";
import axios from "axios";



// const useAuth =()=>{
//     const user ={logdin:true }
//     return user && user.logdin;
// }



export function ProtectedRoutesDisConnected() {
  const { validToken, setValidToken } =
    useContext(DataContext);
    const token =JSON.parse(localStorage.getItem('access_token_client') ) 

  return !token ? <Navigate to= "/" /> : <Outlet /> 

}

export function ProtectedRoutesConnected() {

  const { validToken, setValidToken } =
    useContext(DataContext);
  return !validToken ? <Outlet /> : <Navigate to='/connected' />

}