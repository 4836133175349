import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import "../src/i18next"
import './index.css';
import App from './App';
import { BrowserRouter as Router, Route, Routes,Switch } from "react-router-dom";
import BusinessDetailsPackage from './component/homePage/detailsPackage/businessDetailsPackage/businessDetailsPackage';
import reportWebVitals from './reportWebVitals';
import { createTheme } from "@mui/material/styles"
import ThemeProvider from './dataContext';
// import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";


const themeRtl = createTheme({
  direction: 'rtl', // Both here and <body dir="rtl">
});

const themeLtr = createTheme({
  direction: 'ltr', // Both here and <body dir="rtl">
});
const ltrTheme = document.documentElement.dir === "rtl" ? createTheme({ direction: "rtl" }) : createTheme({ direction: "ltr" });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>

  <ThemeProvider>
    <Suspense fallback={<div>Loading...</div>}>
      {/* <Router>
        <div>
        <Routes>
          <Route exact path="/connected" element={    <BusinessDetailsPackage />}/>

          <Route exact path="/" element={      <App />}/>
          </Routes>
       

      </div>
    </Router> */}
    <App/>
    
     
    </Suspense>
  </ ThemeProvider >

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
