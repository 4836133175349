import { useTranslation } from "react-i18next";
import LocationSearchInput from "../../reactAutoComplete";
import MicrosoftExcel from "../../../images/Microsoft Excel.png";
import trash from "../../../images/Trash.png";
import "./stepsNumCss/address_step.css";
import { useContext, useEffect, useRef, useState } from "react";

import { Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import PopUpForDex from "./popUp";
import PopUpMoreDetails from "./popUpDetails_responsive";
import XlPopUpDex from "./xlFiles/xlFiles_Dex";
import ImportXlFiles from "./xlFiles/xl_file_responsive";
import { DataContext } from "../../../../../dataContext";
import SearchLocation from "../../searchLocation";


export default function Address_point({}) {
  const {
    openMoreDetailsPopUp,
    setOpenMoreDetailsPopUp,
    detailsOfPackage,
    setDetailsOfPackage,
    setErrorAutoComplet,
    showInput,
    setShowInput,
    errorAutoComplet,
    ref,
    setAddress,
    showSearchLocation,
    setShowSearchLocation
  } = useContext(DataContext);

  const [DeliveryDestination, setDeliveryDestination] = useState("");
  const [showListOption, setShowListOption] = useState(null);
  const [fileObject, setFileObject] = useState("");
  const [singelPoint, setSingelPoint] = useState("");

  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const { t, i18n } = useTranslation();
  const refDetails = useRef();

  // setIsClickd(!isClickd)

  const handleOpenMenu = (value) => {
    setDetailsOfPackage((value) => [...value]);
    setTimeout(() => {
      setOpenMoreDetailsPopUp(openMoreDetailsPopUp ? false : true);
      setSingelPoint("");
      setShowSearchLocation(false);
    }, 100); 
  };

  const handleCloseMenu = (singelPackage) => {

    setDetailsOfPackage([...detailsOfPackage]);

    setTimeout(() => {
      setOpenMoreDetailsPopUp(false);

      singelPackage.payForStation = "";
      singelPackage.comment = "";
      singelPackage.contactName = "";
      singelPackage.contactPhone = "";

      singelPackage.interactionType = "";
    }, 100);
    
  };

  const showDetails = (i) => {
    setOpenMoreDetailsPopUp(true);
    setSingelPoint(i);
  };

  const scrollToBottom = () => {
    refDetails.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  };

  useEffect(() => {
    detailsOfPackage?.map((point, i) => {
      if(!point.streetNum || !point.streetNum.length || !point.streetName || !point.streetName.length || !point.cityName || !point.cityName.length)
        detailsOfPackage[i].addressError = true;
      else
        detailsOfPackage[i].addressError = false;
      setDetailsOfPackage(detailsOfPackage);
    });
    if (detailsOfPackage.length > 2 && !openMoreDetailsPopUp) {
      scrollToBottom();
    }
    //16/06/24 RUT CANCELLED open editDetails popup only after confirm button
    if (detailsOfPackage.length && !openMoreDetailsPopUp)
        setSingelPoint(detailsOfPackage[detailsOfPackage.length-1]);
    //   showDetails(detailsOfPackage[detailsOfPackage.length-1]);
  }, [detailsOfPackage]);

  const h5StepNum1 = t("STEPS_NUM_1_HEADER_LINE_2");
  const label = `${t("STEPS_ADDRESS_DELIVERY")} ${
    detailsOfPackage.length > 0 ? detailsOfPackage.length + 1 : ""
  }`;

  const selectFuncStreetName = (e) => {
    if(!detailsOfPackage.length)
      setShowSearchLocation(true);
    setDetailsOfPackage([
      {
        id_package: detailsOfPackage.length + 1,
        lat: 0,
        lng: 0,
        destination: "",
        cityName: detailsOfPackage[0]?.cityName,
        streetName: e.target.value,
        streetNum: detailsOfPackage[0]?.streetNum,
        contactName: "",
        contactPhone: "",
        payForStation: "",
        comment: "",
        currency_symbol_payForStation: "₪",
      },
    ]);  
  };

  const selectFuncStreetNum = (e) => {
    if(!detailsOfPackage.length)
      setShowSearchLocation(true);
    setDetailsOfPackage([
      {
        id_package: detailsOfPackage.length + 1,
        lat: 0,
        lng: 0,
        destination: "",
        cityName: detailsOfPackage[0]?.cityName,
        streetName: detailsOfPackage[0]?.streetName,
        streetNum: e.target.value,
        contactName: "",
        contactPhone: "",
        payForStation: "",
        comment: "",
        currency_symbol_payForStation: "₪",
      },
    ]);   
  };

  const selectFuncCityName = (e) => {
    if(!detailsOfPackage.length)
      setShowSearchLocation(true);
    setDetailsOfPackage([
      {
        id_package: detailsOfPackage.length + 1,
        lat: 0,
        lng: 0,
        destination: "",
        cityName: e.target.value,
        streetName: detailsOfPackage[0]?.streetName,
        streetNum: detailsOfPackage[0]?.streetNum,
        contactName: "",
        contactPhone: "",
        payForStation: "",
        comment: "",
        currency_symbol_payForStation: "₪",
      },
    ]);  
  };

  return openMoreDetailsPopUp && isMobile ? (
    <PopUpMoreDetails
      labelDestination={t(`STEPS_ADDRESS_DELIVERY`)}
      click={handleOpenMenu}
      open={openMoreDetailsPopUp}
      selected={(detailsOfPackage) => setDetailsOfPackage(detailsOfPackage)}
      AllDetailsOfPackage={detailsOfPackage}
      close={handleCloseMenu}
      detailsOfPackageSingel={singelPoint}
      className="moreDetailsWindow"
      isExitAddress={false}
      setAddress={(address)=>{
        setAddress({address:address})
      }}
      errorAutoComplet={errorAutoComplet}
        setErrorAutoComplet={setErrorAutoComplet}
    />
  ) : showInput && isMobile ? (
    <ImportXlFiles
      fileObject={fileObject}
      setFileObject={setFileObject}
      showInput={showInput}
      setShowInput={setShowInput}
      AllDetailsOfPackage={detailsOfPackage}
      selected={(detailsOfPackage) => setDetailsOfPackage(detailsOfPackage)}
    />
  ) : (
    <div className="infoSteps">
      <div className="head_steps_address">
        <h1 className="h1StepNum1">{t("STEPS_NUM_HEADER_LINE_1")}</h1>
        <h5 className="h5StepNum1">
          {detailsOfPackage.length > 0
            ? `${detailsOfPackage.length} ${h5StepNum1}`
            : t("STEPS_NUM_HEADER_LINE_2_SREP_2")}
        </h5>
      </div>
      {/*CANCELED 02/04/2024 add multiple stops to a route*/}
      {/* <div className="flexXlFile">
        <div
          className="img_file"
          style={{ height: isMobile ? "58px" : "62px" }}
        >
          <img
            style={{ cursor: "pointer" }}
            src={MicrosoftExcel}
            alt="Microsoft Excel"
            onClick={() => setShowInput(!showInput)}
          />
        </div>
        <div className="stations">
          <p
            className="btn"
            onClick={() => {
              setIsClickd(!isClickd);
              ref.current && ref.current.focus();
            }}
            style={{
              direction: "initial",
              textAlign: "center",
              color: "#583DFF",
            }}
          >
            {t("STEPS_ADD_STATION")}
          </p>
        </div>
      </div> */}
      {
        (!detailsOfPackage.length || showSearchLocation) && <div
          className="mobile_autocomplete"
          style={{ height: isMobile ? "35px" : "90px" , marginTop: detailsOfPackage.length<2 ? "32px" : "0px" }}
        >
          {/* <LocationSearchInput
            setErrorAutoComplet={setErrorAutoComplet}
            errorAutoComplet={errorAutoComplet}
            detailsOfPackage={detailsOfPackage}
            selected={setDetailsOfPackage}
            label={label}
            setIsClickd={(isClickd) => setIsClickd(isClickd)}
            value={DeliveryDestination}
            onChange={(e) => setDeliveryDestination(e)}
          /> */}
          <SearchLocation
            address={detailsOfPackage[0] ? detailsOfPackage[0] : {}}
            selectFuncCityName={selectFuncCityName}
            selectFuncStreetNum={selectFuncStreetNum}
            selectFuncStreetName={selectFuncStreetName}
            errorAutoComplet={errorAutoComplet}
          />
        </div>
      }

      {/* רשימת תחנות */}
      {detailsOfPackage.length > 0 && !showSearchLocation && (
        <div className="overflow">
          {detailsOfPackage?.map((point, i) => {
            return (
              <div id={point.id_package} key={i}>
                <div
                  id={i}
                  style={{
                    position: "relative",
                    marginBottom:
                      isMobile && showListOption === i && i === 0 && "84px",
                  }}
                  className="deliveryAddress"
                >
                  <p
                    style={{
                      position: "absolute",
                      top: "-10px",
                      right:
                        document.documentElement.dir === "ltr" ? "13px" : "",
                      fontSize: "14px",
                      color: "rgb(79, 89, 118)",
                    }}
                  >
                    {" "}
                    {`${t("STEPS_ADDRESS_DELIVERY")} (${
                      detailsOfPackage.length > 0 ? i + 1 : ""
                    })`}
                  </p>
                  <fieldset
                    aria-hidden="true"
                    className={
                      document.documentElement.dir === "ltr"
                        ? "muirtl-1d3z3hw-MuiOutlinedInput-notchedOutline"
                        : "muiltr-1d3z3hw-MuiOutlinedInput-notchedOutline"
                    }
                  >
                    <legend
                      style={{
                        fontSize:
                          document.documentElement.dir === "rtl" && "16px",
                      }}
                      className={`${
                        document.documentElement.dir === "ltr"
                          ? "muirtl"
                          : "muiltr"
                      }-1in441m`}
                    >
                      {/* <span>
                        
                        {i > 98 && document.documentElement.dir === "ltr"
                          ? '"5h&h'
                          : i > 8
                          ? "76&''"
                          : "75"}
                      </span> */}
                    </legend>
                  </fieldset>

                  <div className="overflow_point">
                    <div className="overflow_point_hidden">
                      <span className="span_destination">
                        {point.destination}
                      </span>{" "}
                      <br />
                    </div>
                    {point.contactPhone !== "" ||
                    point.payForStation !== "" ||
                    point.comment !== "" ||
                    point.contactName !== "" ? (
                      <div>
                        <span className="pointClass">
                          {" "}
                          {point.contactName
                            ? `${point.contactName},`
                            : ""}{" "}
                          {point.contactPhone ? point.contactPhone : ""}{" "}
                          {point.payForStation}
                          {point.payForStation
                            ? point.currency_symbol_payForStation
                            : ""}{" "}
                          {point.comment ? point.comment : ""}{" "}
                        </span>
                      </div>
                    ) : null}{" "}
                    {point.apartment_number !== "" ||
                      point.floor !== "" ||
                      point.entrance !== ""  ? (
                        <div>
                          <span className="pointClass">
                          {point.apartment_number&&point.apartment_number !== "" ? `${t("ADD_DETAILS_WINDOW_INPUT_APARTMENT")} `: ""}
                          {point.apartment_number
                            ? `${point.apartment_number},`
                          : ""}{" "}
                          {point.floor&&point.floor !== "" ? `${t("ADD_DETAILS_WINDOW_INPUT_FLOOR")} `:""}{point.floor ? point.floor : ""}{" "}
                          {point.entrance&&point.entrance !== "" ? `${t("ADD_DETAILS_WINDOW_INPUT_ENTRANCE")} `:""}{point.entrance? point.entrance: ""}{" "}
                          </span>
                        </div>
                      ) : null}{" "}
                  </div>
                  <div
                    onClick={() =>
                      isMobile &&
                      setShowListOption((showListOption) =>
                        showListOption === i ? null : i
                      )
                    }
                    className="details_btn"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <div
                      className={
                        isMobile && showListOption === i
                          ? "div_listOption_mobile"
                          : "div_listOption"
                      }
                    >
                      <span
                        className={`${
                          isMobile && showListOption !== i
                            ? "btn moreDetailsHiiden"
                            : "moreDetails"
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() => showDetails(point)}
                      >
                        {point.contactName !== "" ||
                        point.payForStation !== "" ||
                        point.comment !== "" ||
                        point.contactPhone !== ""
                          ? t("ADD_DETAILS_EDIT")
                          : t("ADD_DETAILS_LINK")}
                      </span>
                      <div>
                        <img
                          className={`${
                            isMobile &&
                            showListOption !== i &&
                            "btn imgMoreDetailsHidden"
                          }`}
                          style={{
                            cursor: "pointer",
                            position:
                              isMobile && showListOption === i
                                ? "absolute"
                                : "",
                            top: "70px",
                            right:
                              document.documentElement.dir === "ltr"
                                ? "12px"
                                : "0px",
                            left:
                              document.documentElement.dir === "rtl"
                                ? "8px"
                                : "0px",
                          }}
                          onClick={() => {
                            setDetailsOfPackage(
                              detailsOfPackage.filter((item) => item !== point)
                            );
                          }}
                          src={trash}
                          alt="trash"
                        />
                        {showListOption === i && isMobile ? (
                          <span className="imgMoreDetailsShow">
                            {t("DELETE_STATION_MOBILE")}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {/*הוספת פרטים למחשב popUp  */}
                  {openMoreDetailsPopUp && !isMobile ? (
                    <PopUpForDex
                      labelDestination={t(`STEPS_ADDRESS_DELIVERY`)}
                      click={handleOpenMenu}
                      open={openMoreDetailsPopUp}
                      selected={(detailsOfPackage) =>
                        setDetailsOfPackage(detailsOfPackage)
                      }
                      AllDetailsOfPackage={detailsOfPackage}
                      close={handleCloseMenu}
                      detailsOfPackageSingel={singelPoint}
                      setAddress={(address)=>{
                        setAddress({address:address})
                      }}
                      errorAutoComplet={errorAutoComplet}
                      setErrorAutoComplet={setErrorAutoComplet}
                      className="moreDetailsWindow"
                    />
                  ) : null}
                </div>
                {point.addressError && <span className="address-error">{t("DETAILS_OF_PACKAGE_INPUT_ADDRESS_LABEL")}</span>} 
              </div>
            );
          })}
          <div ref={refDetails} />
        </div>
      )}
      {showInput && !isMobile ? (
        <XlPopUpDex
          fileObject={fileObject}
          setFileObject={setFileObject}
          showInput={showInput}
          setShowInput={setShowInput}
          AllDetailsOfPackage={detailsOfPackage}
          selected={(detailsOfPackage) => setDetailsOfPackage(detailsOfPackage)}
        />
      ) : null}
    </div>
  );
}
