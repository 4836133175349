import { useTranslation } from "react-i18next";
import "./stepsNum/stepsNumCss/checkTypePackage&postData.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { TextField, CssBaseline } from "@mui/material";
import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { Button } from "@mui/material";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import { useTheme } from "@emotion/react";
import { verificationCheck } from "./verificationSmsApi";
import { smsValidationApi } from "./sms_validation_api";
import "../MultiSteps/popUpValidPhone.css";
import { DataContext } from "../../../../dataContext";
import { updateServer } from "./stepsNum/updateServer";
import StripeContainer from "../../login/payment/elements";
import is from "date-fns/esm/locale/is/index.js";
import LoadingButton from "@mui/lab/LoadingButton";
import ErrorAlert from "../../detailsOfBusiness/errorMessage";
import Box from "@mui/material/Box";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

export default function PopUpPaymentDetails({}) {
  const {
    checkboxValue,
    detailsOfPackage,
    setGetDataFromServer,
    exitAddress,
    time,
    nameAndPhone,
    getDataFromServer,
    setResponseSmsValid,
    switchEditBtnSummary,
    components,
    paymentDetailsValid,
    errorsCreditCard,
    paymentMethodId,
    setPaymentMethodId,
    setPaymentDetailsValid,
    client_secret,
    setClientSecret,
  } = useContext(DataContext);

  useEffect(() => {
    // const customer_phone = JSON.parse(
    //   localStorage.getItem("access_customer_phone")
    // )
    //   ? JSON.parse(localStorage.getItem("access_customer_phone")).customer_phone
    //   : "";
    paymentMethodId &&
      axios
        .post(
          "/pogo_connect_api/server/web/index.php/customers/validate_payment_method",
          {
            phone: nameAndPhone?.phoneNumber,
            payment_method_id: paymentMethodId,
          }
        )
        .then((res) => {
          console.log(res.status);
          console.log(res.data);
          setLoading(false);
          setOpen(false);
          setPaymentDetailsValid("payment valid");
        })
        .catch(function (error) {
          console.log(error);
          setPaymentMethodId(false);
          setPaymentDetailsValid(null);
        });
  }, [paymentMethodId]);

  const { token } = useParams();
  const { t, i18n } = useTranslation();
  const [errorBusinnesDetails, setErrorBusinnesDetails] = useState(false);
  const [response, setResponse] = useState(null);
  const [value, setValue] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const ref = useRef(null);

  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const handleBack = () => {
    // setOpen(false);
    setResponseSmsValid(null);
    // if (activeStep === 0) {
    //   return setShowSteps(false);
    // }
    // if (activeStep === 2) {
    //   console.log(val, getDataFromServer.new_price);
    //   getDataFromServer.new_price = val;
    //   setGetDataFromServer({ ...getDataFromServer });
    // }
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const checkDetails = async () => {
    if (errorsCreditCard.error == false) {
      //send post with id and card owner name to the server
      setLoading(true);
      try {
        const response = await axios.post(
          "/pogo_connect_api/server/web/index.php/customers/create_customer",
          {
            phone: nameAndPhone?.phoneNumber,
          }
        );
        // if (update_credit_card) {
        //   setClientSecret("");
        //   setClientSecret(response.data.client_secret);
        //   setUpdate_credit_card(false);
        // }
        // if (response.data.client_secret && !update_credit_card) {
        if (response.data.client_secret) {
          console.log("Successful payment", response.data);
          // client_secret && setClientSecret(false)
          setClientSecret(response.data.client_secret);
          console.log({
            client: response.data.client_secret,
            client_secret: client_secret,
          });
          console.log(paymentMethodId);
          //   setLoading(false);
          //   setOpen(false);
          //   setPaymentDetailsValid("payment valid");
        }
      } catch (error) {
        console.log("Error", error);
        setErrorBusinnesDetails(true);
        setPaymentDetailsValid(null);
        // setOpen(false);
        setLoading(false);
      }
    } else {
      return;
    }
  };

  const checkCodeSms = async () => {
    // const result = await verificationCheck({ nameAndPhone, value });
    const result = await verificationCheck({ nameAndPhone, value });
    if (result == "OK") {
      //    console.log(result);
      setOpen(false);
      setPaymentDetailsValid("payment verified");
    } else {
      return setError(true);
    }
  };

  const backToPhoneAndName = () => {
    switchEditBtnSummary("fullNameAndPhone");
    setResponseSmsValid(false);
  };

  const cacheLtr = createCache({
    key: "muiltr",
  });

  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });

  const ltrTheme =
    document.documentElement.dir === "rtl"
      ? createTheme({ direction: "rtl" })
      : createTheme({ direction: "ltr" });

  return (
    <Dialog
      hideBackdrop
      sx={{
        "& .MuiPaper-root": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "40px 4px 50px",
          gap: "10px",
          height: "1199px",
          maxWidth: "710px",
          width: "100%",
          //    boxShadow:"none",
          //     color:"white",
          transition: "none",
          background: "#FFFFFF",
          borderRadius: "4px",
        },
      }}
      open={open}
      onClose={setOpen}
    >
      <CacheProvider value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}>
        <CssBaseline />
        <DialogContent sx={{ width: "100%", maxWidth: "inherit" }}>
          <div style={{ width: "100%" }} className="moreDetailsHead">
            {" "}
            <h2
              style={{ textAlign: isMobile && "right", width: "100%" }}
              className="h2HeaderMoreDetails"
            >
              {t("PAYMENT_DETAILS")}
            </h2>
          </div>
          <StripeContainer setErrorBusinnesDetails={setErrorBusinnesDetails} />
          <div
            style={{
              marginBottom: !isMobile && "25px",
              height: isMobile && "96px",
            }}
          >
            <CacheProvider
              value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
            >
              <TextField
                placeholder={t("CREDIT_NAME")}
                autoComplete="off"
                //   onChange={handleChangefullNameCardOwner}
                //   value={
                //     allDetailsofBusinnes.fullNameCardOwner
                //       ? allDetailsofBusinnes.fullNameCardOwner
                //       : ""
                //   }
                label={t("CREDIT_NAME")}
                id="outlined-size-small"
                sx={{
                  width: "100%",
                  height: !isMobile ? "81px" : "96px",
                  "& .MuiOutlinedInput-root": {
                    fontFamily: "Heebo",
                    fontSize: "16px",
                    height: "71px",
                    "& fieldset": {
                      direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    height: "71px",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "17px",
                    lineHeight: "25px",
                    textAlign: "right",
                    color: "#0D152E",
                    direction: ltrTheme.direction === "ltr" ? "rtl" : "ltr",
                  },
                }}
                InputLabelProps={{
                  style: {
                    margin: "auto",
                    display: "flex",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "25px",
                    textAlign: "right",
                    color: "#0D152E",
                  },

                  shrink: true,
                }}
              />
            </CacheProvider>
          </div>
          <div style={{ marginBottom: "45px" }}>
            <CacheProvider
              value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
            >
              <TextField
                placeholder={t("ID")}
                autoComplete="off"
                //   onChange={handleChangeId}
                //   value={allDetailsofBusinnes.id ? allDetailsofBusinnes.id : ""}
                label={t("ID")}
                id="outlined-size-small"
                sx={{
                  width: "100%",
                  height: "81px",
                  "& .MuiOutlinedInput-root": {
                    fontFamily: "Heebo",
                    fontSize: "16px",
                    height: "71px",
                    "& fieldset": {
                      direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                    },
                  },
                }}
                inputProps={{
                  style: {
                    height: "71px",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "17px",
                    lineHeight: "25px",
                    textAlign: "right",
                    color: "#0D152E",
                    direction: ltrTheme.direction === "ltr" ? "rtl" : "ltr",
                  },
                }}
                InputLabelProps={{
                  style: {
                    margin: "auto",
                    display: "flex",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "25px",
                    textAlign: "right",
                    color: "#0D152E",
                  },

                  shrink: true,
                }}
              />
            </CacheProvider>
          </div>
          {errorBusinnesDetails && (
            <ErrorAlert setErrorBusinnesDetails={setErrorBusinnesDetails} />
          )}
          <DialogActions>
            <div className="nextAndBackBtn">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  pb: 1.2,
                  pt: 1.2,
                  width: 545,
                  // maxWidth: isTabletOrMobile ? 500 : 545,
                }}
              >
                {!loading && (
                  <Button
                    onClick={checkDetails}
                    variant="outlined"
                    style={{
                      textTransform: "none",
                      padding: "13px 15px",
                      color: "#FFFFFF",
                      textAlign: "center",
                      width: isMobile ? "50%" : "450px",
                      background: "#583DFF",
                    }}
                  >
                    {" "}
                    {t("DETAILS_OF_PACKAGE_CONTINUE")}{" "}
                  </Button>
                )}
                {loading && (
                  <LoadingButton
                    variant="contained"
                    // className="saveMoreDetails"
                    sx={{
                      //   height: "62px",
                      textTransform: "none",
                      //   background: "#583DFF",
                      background: "#AC81FF",
                      width: "450px",

                      // width: isMobile
                      //   ? "100%"
                      //   : location.pathname == "/details"
                      //   ? "100%"
                      //   : "71%",
                      margin: isMobile && "0px",
                    }}
                    loading={loading}
                  ></LoadingButton>
                )}
                <Box sx={{ flex: "1 1 auto" }} />{" "}
                <Button
                  style={{
                    textTransform: "none",
                    marginRight: "0px",
                    width: "20%",
                    // height: heightResponsive(),
                    borderRadius: "4px",
                    borderColor: "#583DFF",
                  }}
                  variant="outlined"
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  <ArrowRightAltIcon className="ArrowEN" />{" "}
                  {/* <img className='Arrow' style={{background: '#583DFF',transform: 'scaleX(-1)'}} src={Arrow} alt='Arrow'/> */}{" "}
                </Button>
              </Box>{" "}
            </div>
          </DialogActions>{" "}
        </DialogContent>
      </CacheProvider>
    </Dialog>
  );
}
