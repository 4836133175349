import axios from "axios";

export async function updateServer({
  checkboxValue,
  detailsOfPackage,
  getDataFromServer,
  setGetDataFromServer,
  exitAddress,
  nameAndPhone,
  time,
  type,
  token,
  validToken,
  setValidToken,
  sumitCusId,
  setSumitCusId,
  paymentMethodId,
  setPaymentMethodId,
  errorsCreditCard,
  setErrorsCreditCard,
  setMoveToDetailsOfCreditCard,
  testUser,
  updateBeforeConfirmingPrice=false
}) {

  var url = null;
  if(validToken)
    url = "/pogo_connect_api/server/web/index.php/customers/post_update_track";
  else
    url = `pogo_connect_api/server/web/index.php/tracks/post_update_track`;

  // old: Before changing a date to a selection list
  //const arrayPickUpTime = time?.pickUpTime?.split("T");
  //const arrayDdLine = time?.ddLine?.split("T");

  //const start_time = [arrayPickUpTime[0], arrayPickUpTime[1]]?.join(" ");
  //const end_time = [arrayDdLine[0], arrayDdLine[1]]?.join(" ");

  const start_time = time?.pickUpTime ? time?.pickUpTime : "1";
  const end_time = time?.ddLine ? time.ddLine : "1";

  setGetDataFromServer({ ...getDataFromServer });
  const ordersAddress = detailsOfPackage?.map((order, i) => {
    return {
      address: {
        lat: order.lat,
        lng: order.lng,
        cityName: order.cityName,
        streetName: order.streetName,
        streetNum: order.streetNum,
      },
      order_price: order.payForStation,
      note: order.comment,
      contactName: order.contactName,
      contactPhone: order.contactPhone,
      interactionType: order.pickOrDrop,
      apartment_number: order.apartment_number,
      floor: order.floor,
      entrance: order.entrance
    };
  });

  const data = {
    exit_address: {
      cityName: exitAddress?.cityName || "",
      streetName: exitAddress?.streetName || "",
      streetNum: exitAddress?.streetNum || "",
      lat: exitAddress?.lat || "",
      lng: exitAddress?.lng || "",
    },
    type: type,
    start_time: start_time,
    end_time: end_time,
    vehicles_car: checkboxValue ? checkboxValue?.CarChecked : "1",
    vehicles_truck: checkboxValue ? checkboxValue?.TruckChecked : "1",
    vehicles_motorcycle: checkboxValue ? checkboxValue?.MotorcycleChecked : "1",
    orders: ordersAddress,
    price: getDataFromServer?.new_price,
    currency_symbol: getDataFromServer?.currency_symbol,
    customer_name: nameAndPhone ? nameAndPhone?.fullName : "",
    phone: nameAndPhone ? nameAndPhone?.phoneNumber : "",
    erea_code: nameAndPhone?.erea_code,
    driver_app: "pogo connect",
    track_id: getDataFromServer?.track_id
      ? getDataFromServer?.track_id
      : token
      ? token
      : null,
    submit: !updateBeforeConfirmingPrice
  };

  try {
    var result = null;
    if(validToken){
      var cus_id = null;var payment_method_id = null;
      if(!sumitCusId || !paymentMethodId){
        try {
          const result = await axios.get(
            "/pogo_connect_api/server/web/index.php/customers/get_details",
            {
              headers: {
                //   Authorization: `Bearer ${validToken?.access_token}`,
                Authorization: `Bearer ${validToken.access_token}`,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
            }
          );
          if(result.data.stripe_id) {
            cus_id = parseInt(result.data.stripe_id);
            setSumitCusId(cus_id);
          }
          if (result.data.card_number) {
            if(result.data.stripe_payment_method_id){
              setPaymentMethodId(result.data.stripe_payment_method_id);
              payment_method_id = result.data.stripe_payment_method_id;
            }
          }
        } catch (error) {
          console.log(error);
        }
      }else{
        cus_id = sumitCusId;
        payment_method_id = paymentMethodId;
      }
      //Charge customer
      const itemData = {
        "IncomeItem": {
          "Name": " משלוח מהיר בפוגו "+getDataFromServer?.track_id.slice(0,6),
          "Description": " Pogo connect new delivery "+getDataFromServer?.track_id.slice(0,6),
          "Price": getDataFromServer?.new_price ? getDataFromServer?.new_price : getDataFromServer?.price_min
        },
        "Credentials": {
          "CompanyID": 52536134,
          "APIKey": "QgmPSe3W4m6J9ZLMMQXpiE4RfAqAEgQ07AHHa5v74O9WTeBSwf"
        }
      };
      const item = await axios.post("https://api.sumit.co.il/accounting/incomeitems/create/", itemData);
      const itemId = item.data.Data.EntityID;
      //TODO dispalay error message about item is null/getDataFromServer is null
      const price = getDataFromServer?.new_price ? (getDataFromServer?.new_price/1.17) : (getDataFromServer?.price_min/1.17)
      if(!testUser){
        const paymentData = {
          "Customer": {
            "ID": cus_id
          },
          "PaymentMethod": {
            "ID": payment_method_id,
          },
          "Items": [
            {
              "Item" : {
                "ID" : itemId
              },
              "UnitPrice": price
            }
          ],
          "UpdateCustomerByEmail_AttachDocument": true,
          "UpdateCustomerByEmail_Language": null,
          "SendDocumentByEmail": true,
          "Credentials": {
            "CompanyID": 52536134,
            "APIKey": "QgmPSe3W4m6J9ZLMMQXpiE4RfAqAEgQ07AHHa5v74O9WTeBSwf"
          }
        };
        const paymentResult = await axios.post("https://api.sumit.co.il/billing/payments/charge/", paymentData);
        if (paymentResult.data.UserErrorMessage || !paymentResult.data.Data.Payment.ValidPayment) {
          //Error if payment method is invalid or other error while charging
          setMoveToDetailsOfCreditCard(true);
          setErrorsCreditCard({
            error: true,
            empty: false,
          });
          return;
        }
      }
      
      //If charging success continue send route...
      result = await axios.post(
        url,
        data,
        {
          headers: {
            Authorization: `Bearer ${validToken?.access_token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );
    }else{
      result = await axios.post(url, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
    }

    // setResponseUpdate(result.data)

    // getDataFromServer = {
    //   track_id: result.data.track_id,
    //   new_price: result.data.price,
    //   price_min: Math.round(result.data.price_min),
    //   track_minutes: result.data.track_minutes,
    //   track_distance: result.data.track_distance,
    //   count_orders: result.data.count_orders,
    //   currency_symbol: result.data.currency_symbol,
    //   isLoading: false,
    // };
    getDataFromServer.track_id = result.data.track_id;
    getDataFromServer.new_price = result.data.price;
    getDataFromServer.price_min = Math.round(result.data.price_min * 1);
    getDataFromServer.track_minutes = result.data.track_minutes;
    getDataFromServer.track_distance = result.data.track_distance;
    getDataFromServer.count_orders = result.data.count_orders;
    getDataFromServer.currency_symbol = result.data.currency_symbol;
    getDataFromServer.isLoading = false;

    setGetDataFromServer({ ...getDataFromServer });
    //setGetDataFromServer()
    //time_convert(getDataFromServer?.track_minutes, setMinutesToDateTime);
    return result.data.status;
  } catch (error) {
    if (error && error.response && error.response.status == 401) {
      localStorage.removeItem("access_token_client");
      setValidToken(false);
    }
    getDataFromServer.isLoading = "error";
    setGetDataFromServer({ ...getDataFromServer });
    return "error";
  }
}
