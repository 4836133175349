import Logo from "./logoHeader";
import pogo from "../images/pogo.png";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import deliverImg from "../images/deliverImg.png";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { Container } from "react-bootstrap";
import { Button } from "@mui/material";
import Login from "../login/login";
import PopUpForDex from "../detailsOfBusiness/details_business";
import "./header.css";
import { useMediaQuery } from "react-responsive"
import FormControl from "@mui/material/FormControl";
import ArrowDown from "../images/arrowDown.png"
import { useTranslation } from "react-i18next";
import DetailsOfBusiness from "../detailsOfBusiness/details_business";
import { DataContext } from "../../../dataContext";
import { useContext } from "react";

export default function Header() {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 650px)' })
  const [lng, setLng] = useState(``);
  const [isLogin, setIsLogin] = useState(true)
  // const [moveToDetailsOfBusinnes, setMoveToDetailsOfBusinnes] = React.useState(null)
const{
  showDetailsBusiness,
  setShowDetailsBusiness,
  openLoginPopup,
  setOpenLoginPopup
}=useContext(DataContext)
  // const [openDetailsPopUp, setOpenDetailsPopUp] = useState(false)
  const [options, setOptions] = useState('username')
  const [showMenu, setShowMenu] = useState(false)
const navigate = useNavigate();
  useEffect(() => {
    i18n.changeLanguage(lng);
    const dir = lng === "en" ? "rtl" : "ltr";
    document.documentElement.dir = dir;
  }, [lng]);

  

  return (
    <section className="mobile_height">
      <div className="container header_logo hamburger">
        <div className="header_logo_height">
          {/* <span className="icon-bar"></span> */}
        <div className="flex_btns" >
          {!isMobile && <div style={{ position: "" ,marginRight:"1rem"}}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Language</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                sx={{
                  "& svg": { color: "#583DFF" },
                  //   "& fieldset": {
                  //     display: "none",
                  //   },
                  minWidth: 120,
                }}
                IconComponent={() => <img src={ArrowDown} alt='arrow_down' style={{ marginRight: '7px', cursor: "pointer" }} />}
                style={{ color: "#583DFF", borderWidth: "0px" }}
                value={lng}
                label="language"
                inputProps={{ "aria-label": "Without label" }}
                onChange={(e) => setLng(e.target.value)}
              >
                <MenuItem style={{ color: "#583DFF" }} value={`en`}>
                  English
                </MenuItem>
                <MenuItem style={{ color: "#583DFF" }} value={`he`}>
                  עברית
                </MenuItem>
              </Select>
            </FormControl>
          </div>

              }
          <div>
          
            <div className="loginBtn" onClick={() => setOpenLoginPopup(true)} style={{ cursor: "pointer" }}><span className="span_login">{t('LOGIN_BTN')}</span></div>
          </div>
</div>
        {openLoginPopup ? <Login open={openLoginPopup} setOpen={setOpenLoginPopup} /> : null}
        {showDetailsBusiness ? <DetailsOfBusiness open={showDetailsBusiness} setShowDetailsBusiness={setShowDetailsBusiness} /> : null}

        <div className="logoPogo">
          <img className="logo_position" src={pogo} alt="pogo" />
          <div style={{ position: "relative" }}>
            <a className="linkHomePage" href="#">
              {t("HEADER_LOGO")}
            </a>
          </div>
        </div>
      </div>
      <div className="flexImgLogo">
        <div>
          <img className="img-fluid" src={deliverImg} alt="deliverImg" />
        </div>
        <Logo language={lng} />
      </div>
    </div>
    </section >
  );
}
