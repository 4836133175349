import * as React from "react";
import "./stepsNumCss/popUp.css";
import { TextField, CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import Button from "@mui/material/Button";
import { useMediaQuery } from "react-responsive";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import InputAdornment from "@mui/material/InputAdornment";
import parsePhoneNumber from "libphonenumber-js";
import Arrow3 from "../../../images/Arrow 3.png";
import trash from "../../../images/Trash.png";
import { t } from "i18next";
import DetailsOfPackage from "../../detailsOfPackage";
import { type } from "@testing-library/user-event/dist/type";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import LocationSearchInput from "../../reactAutoComplete";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import is from "date-fns/esm/locale/is/index.js";
import SearchLocation from "../../searchLocation";

export default function PopUpMoreDetails({
  open,
  AllDetailsOfPackage,
  close,
  click,
  labelDestination,
  selected,
  detailsOfPackageSingel,
  isExitAddress,
  setAddress,
  errorAutoComplet,
  setErrorAutoComplet
}) {
  const [error, setError] = React.useState(null);
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 650px)" });
  const [errorApartmentNumber, setErrorApartmentNumber] = React.useState(false);
  const [errorFloor, setErrorFloor] = React.useState(false);
  const [errorEntrance, setErrorEntrance] = React.useState(false);
  const [errorName, setErrorName] = React.useState(false);
  //const [checkedRadio,setCheckedRadio=React.useState();
  const [checkedRadio, setCheckedRadio] = React.useState(isExitAddress ? "pickup" : "dropoff");

  const selectFuncPayForStation = (e) => {
    detailsOfPackageSingel.payForStation = e.target.value;
    isExitAddress ?  selected(AllDetailsOfPackage) : selected([...AllDetailsOfPackage]);
  };
  const selectFuncCityName = (e) => {
    detailsOfPackageSingel.cityName = e.target.value;
    const newDetailsOfPackageSingel = {
      ...detailsOfPackageSingel,
      cityName: e.target.value
    };
    isExitAddress ?  selected(newDetailsOfPackageSingel) : selected([...AllDetailsOfPackage]);  
  };
  const selectFuncStreetName = (e) => {
    detailsOfPackageSingel.streetName = e.target.value;
    const newDetailsOfPackageSingel = {
      ...detailsOfPackageSingel,
      streetName: e.target.value
    };
    isExitAddress ?  selected(newDetailsOfPackageSingel) : selected([...AllDetailsOfPackage]);  
  };
  const selectFuncStreetNum = (e) => {
    detailsOfPackageSingel.streetNum = e.target.value;
    const newDetailsOfPackageSingel = {
      ...detailsOfPackageSingel,
      streetNum: e.target.value
    };
    isExitAddress ?  selected(newDetailsOfPackageSingel) : selected([...AllDetailsOfPackage]);  
  };
  const selectFuncComment = (e) => {
    detailsOfPackageSingel.comment = e.target.value;
    const newDetailsOfPackageSingel = {
      ...detailsOfPackageSingel,
      comment: e.target.value
    };
    isExitAddress ?  selected(newDetailsOfPackageSingel) : selected([...AllDetailsOfPackage]);
  };

  const selectFuncCostumerName = (e) => {
    if(e.target.value)
      setErrorName(false);
    detailsOfPackageSingel.contactName = e.target.value;
    isExitAddress ?  selected(AllDetailsOfPackage) : selected([...AllDetailsOfPackage]);  
  };

  const selectFuncApartmentNumber = (e) => {
    if(e.target.value)
        setErrorApartmentNumber(false);
    detailsOfPackageSingel.apartment_number = e.target.value;
    const newDetailsOfPackageSingel = {
      ...detailsOfPackageSingel,
      apartment_number: e.target.value
    };
    isExitAddress ?  selected(newDetailsOfPackageSingel) : selected([...AllDetailsOfPackage]);  
  };


  const selectFuncFloor = (e) => {
    if(e.target.value)
      setErrorFloor(false);
    detailsOfPackageSingel.floor = e.target.value;
    const newDetailsOfPackageSingel = {
      ...detailsOfPackageSingel,
      floor: e.target.value
    };
    isExitAddress ?  selected(newDetailsOfPackageSingel) : selected([...AllDetailsOfPackage]);  
  };

  const selectFuncEntrance = (e) => {
    if(e.target.value)
      setErrorEntrance(false);
    detailsOfPackageSingel.entrance = e.target.value;
    const newDetailsOfPackageSingel = {
      ...detailsOfPackageSingel,
      entrance: e.target.value
    };
    isExitAddress ?  selected(newDetailsOfPackageSingel) : selected([...AllDetailsOfPackage]);  
  };

  const searchLocation = async() => {
    // if(detailsOfPackageSingel.lat && detailsOfPackageSingel.lat != '' 
    //   && detailsOfPackageSingel.lng && detailsOfPackageSingel.lng != ''){
    //     return true;
    // }
    try {
      const address = detailsOfPackageSingel.streetName+" "+detailsOfPackageSingel.streetNum+", "+detailsOfPackageSingel.cityName;
      const response = await axios.get(
          "https://service.optiroutes.com/services/addresses/Location.php?source=PogoConnect&FullAddress="+address//`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyD1dxX07Fr0oh65uGPrJbgGvf3O41mzSZk`
      );
      const results = response;//.data
      if (results.data && results.data.ErrorCode == 1) {
        //const { lat, lng } = results[0].geometry.location;
        const lat = results.data.lat;
        const lng = results.data.lng;
        detailsOfPackageSingel.lat = lat;
        detailsOfPackageSingel.lng = lng;
        detailsOfPackageSingel.destination = results.data.Address ? results.data.Address : results.data.FullAddress;
        //setAddress(results[0].formatted_address);
      } else {
        return false;
        console.log('No results found for the address'+address);
      }
    } catch (error) {
      return false;
      // Handle error
      console.error('Error fetching geocode:', error);
    }
    
    const newDetailsOfPackageSingel = {
      ...detailsOfPackageSingel
    };
    isExitAddress ?  selected(newDetailsOfPackageSingel) : selected([...AllDetailsOfPackage]);  
    return true;
  };

  const handleRadio = (e) => {
    console.log("change");
    detailsOfPackageSingel.pickOrDrop = e.target.value;
    isExitAddress ?  selected(AllDetailsOfPackage) : selected([...AllDetailsOfPackage]);
    setCheckedRadio(e.target.value);
  };

  const selectFunCcontactPhone = ({ target: { value } }) => {
    let valid = true;
    const newPhoneNumber = parsePhoneNumber(value, "IL");

    if (newPhoneNumber) {
      valid = newPhoneNumber.isValid();
      if (valid) {
        value = newPhoneNumber.number;
      }
    }
    setError(!valid);
    detailsOfPackageSingel.contactPhone = value;
    isExitAddress ?  selected(AllDetailsOfPackage) : selected([...AllDetailsOfPackage]);
  };

  const saveDetails = async () => {
    if(!detailsOfPackageSingel.apartment_number){
      return setErrorApartmentNumber(t("APARTMENT_NUMBER_IS_MISSING"));
    }
    if(!detailsOfPackageSingel.floor){
      return setErrorFloor(t("FLOOR_IS_MISSING"));
    }
    if(!detailsOfPackageSingel.entrance){
      return setErrorEntrance(t("ENTRANCE_IS_MISSING"));
    }
    if(!isExitAddress){
      if(!detailsOfPackageSingel.contactName){
        return setErrorName(t("NAME_IS_MISSING"));
      }
      if(!detailsOfPackageSingel.contactPhone){
        return setError(true);
      }
    }
    if(await searchLocation() == false){
      return setErrorAutoComplet(t("DETAILS_OF_PACKAGE_INPUT_ADDRESS_ERROR"));
    }
    setErrorAutoComplet(false);
    click(AllDetailsOfPackage);
  };

  const cacheLtr = createCache({
    key: "muiltr",
  });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });
  const ltrTheme =
    document.documentElement.dir === "rtl"
      ? createTheme({ direction: "rtl" })
      : createTheme({ direction: "ltr" });
  const labelText = `${labelDestination}${AllDetailsOfPackage.length !== undefined ? `(${AllDetailsOfPackage.length})` : ''}`;
  const InputLabelProps = {
    display: "flex",
    fontFamily: "Heebo",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "25px",
    textAlign: "right",
    color: "#0D152E",
    direction: ltrTheme.direction === "rtl" && "ltr",
  };

  const isRTL = (s) => {
    if (s !== "") {
      var ltrChars =
          "A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF" +
          "\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF",
        rtlChars = "\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC",
        rtlDirCheck = new RegExp("^[^" + ltrChars + "]*[" + rtlChars + "]");

      return rtlDirCheck.test(s);
    }
    return true;
  };

  const deletePackageSingel = () => {
    if(isExitAddress) 
      selected(AllDetailsOfPackage);
    else
      selected(AllDetailsOfPackage.filter((item) => item !== detailsOfPackageSingel));
    close(detailsOfPackageSingel);
  };

  return (
    <div className="mobile_width_popUp" style={{direction:ltrTheme.direction === "ltr" ? "rtl" : "ltr"}}>
      {" "}
      {isMobile ? (
        <div className="cancel_mobile">
          {" "}
          <img
            className="Arrow_cancel"
            src={Arrow3}
            onClick={() => close(detailsOfPackageSingel)}
          />{" "}
        </div>
      ) : (<div className="widthActions">
      <div
        style={{
          display: "flex",
          justifyContent: isMobile ? "center" : "space-between",
          marginBottom: "20px",
        }}
      >
        {(
          <Button
            style={{ textTransform: "none" }}
            variant="outlined"
            className="cancelMoreDetails"
            onClick={() => close(detailsOfPackageSingel)}
          >
            {" "}
            {t("ADD_DETAILS_WINDOW_BTN_CANCEL")}{" "}
          </Button>
        )}{" "}
      </div>{" "}
    </div>)}{" "}
      <CssBaseline />
      <div
        className="divPopUp"
        style={{ maxWidth: isTabletOrMobile ? "445px" : "545px" }}
      >
        <div className="moreDetailsHead">
          {" "}
          <h2 className="h2HeaderMoreDetails">
            {" "}
            {t("ADD_DETAILS_WINDOW")}{" "}
          </h2>{" "}
        </div>{" "}
        <div style={{ width: isMobile && "100%" }}>
          <CacheProvider
            value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
          >
            {" "}
            {/* <FormControl className="deliveryRadio"> */}{" "}
            <FormControl
              className="costumerName_width"
              sx={{
                direction:
                  document.documentElement.dir === "ltr" ? "ltr" : "inherit",
              }}
            >
              <RadioGroup
                row
                //aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={checkedRadio}
                sx={{
                  textAlign: isMobile && "end",
                  fontFamily: "Heebo",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "30px",
                  direction:
                    document.documentElement.dir === "ltr" ? "ltr" : "inherit",
                  //width: isMobile ? "100%" : "auto",
                  color: "#0D152E",
                }}
              >
                <FormControlLabel
                  value="pickup"
                  //className="RadioNotSelectedText"
                  //control={<Radio sx={{ display: "none" }} />}
                  control={<Radio />}
                  //checked={checkedRadio}
                  onChange={handleRadio}
                  label={
                    <div>
                      {" "}
                      {t("ADD_DETAILS_WINDOW_COLLECTION")}{" "}
                      {/* <ArrowDownward></ArrowDownward> */}{" "}
                    </div>
                  }
                />{" "}
                <FormControlLabel
                  value="dropoff"
                  //className="RadioSelectedText"
                  //checked={checkedRadio}
                  onChange={handleRadio}
                  //control={<Radio sx={{ display: "none" }} />}
                  control={<Radio />}
                  sx={
                    {
                      // display: "flex",
                      // flexdirection: "row",
                      // justifycontent: "center",
                      // alignitems: "center",
                      // padding: "12px 14px",
                      // gap: "5px",
                      // width: "125px",
                      // height: "43px",
                      // background: "#FFFFFF",
                      // boxshadow: "0px 1px 4px rgba(0, 0, 0, 0.04)",
                      // borderradius: "7px",
                      // flex: "none",
                      // order: "1",
                      // alignself: "stretch",
                      // flexgrow: "1",
                    }
                  }
                  label={
                    <div>
                      {" "}
                      {t("ADD_DETAILS_WINDOW_DELIVERY")}{" "}
                      {/* <ArrowUpward></ArrowUpward> */}{" "}
                    </div>
                  }
                />{" "}
              </RadioGroup>{" "}
            </FormControl>{" "}
            <div className="popUp_flex" style={{marginBottom:"45px"}}>
              {/* <LocationSearchInput
                errorAutoComplet={errorAutoComplet}
                setErrorAutoComplet={setErrorAutoComplet}
                exitAddress={detailsOfPackageSingel}
                setExitaddress={(details)=>{
                  console.log(details);
                  detailsOfPackageSingel = details;
                  isExitAddress ?  selected(detailsOfPackageSingel) : selected([...AllDetailsOfPackage]);}
                }
                label={t("DETAILS_OF_PACKAGE_INPUT_ADDRESS")}
                value={detailsOfPackageSingel.destination}
                onChange={(add) => {
                  detailsOfPackageSingel.destination = add;
                  isExitAddress ?  selected(detailsOfPackageSingel) : selected([...AllDetailsOfPackage]);
                  //setAddress(add);
                }
              }
              /> */}
              {/* <TextField
                disabled
                label={labelText}
                id="outlined-size-small"
                value={detailsOfPackageSingel.destination}
                sx={{
                  width: "100%",
                  height: "81px",
                  "& .MuiOutlinedInput-root": {
                    fontFamily: "Heebo",
                    fontSize: "16px",
                    "& .Mui-disabled": {
                      WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                    },
                    "& fieldset": {
                      direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                    },
                  },
                }}
                inputProps={{
                  style: {
                    direction: ltrTheme.direction === "rtl" ? "ltr" : "rtl",
                  },
                }}
                InputLabelProps={{
                  style: InputLabelProps,
                  shrink: true,
                }}
              />{" "} */}
            </div>{" "}
          </CacheProvider>{" "}

          <CacheProvider
            value={
              ltrTheme.direction === "ltr" &&
              isRTL(detailsOfPackageSingel.contactName)
                ? cacheRtl
                : cacheLtr
            }
          >
          <SearchLocation
            address={detailsOfPackageSingel}
            selectFuncCityName={selectFuncCityName}
            selectFuncStreetNum={selectFuncStreetNum}
            selectFuncStreetName={selectFuncStreetName}
            errorAutoComplet={errorAutoComplet}
          />
          <div className="costumerDetails_width">    
              
            <TextField
                error={errorApartmentNumber}
                helperText={
                  errorApartmentNumber
                }
                autoComplete="off"
                onChange={selectFuncApartmentNumber}
                value={detailsOfPackageSingel.apartment_number}
                label={t("ADD_DETAILS_WINDOW_INPUT_APARTMENT")}
                id="outlined-size-small"
                sx={{
                  width: "24%",
                  height: "81px",
                  marginRight: "5px",
                  "& .MuiOutlinedInput-root": {
                    fontSize: "16px",
                    fontFamily: "Heebo",

                    "& fieldset": {
                      direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                    },
                  },
                }}
                inputProps={{
                  style: {
                    direction:
                      ltrTheme.direction === "ltr" &&
                      isRTL(detailsOfPackageSingel.apartment_number)
                        ? "rtl"
                        : "ltr",
                  },
                }}
                InputLabelProps={{
                  style: {
                    margin: "auto",
                    display: "flex",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "25px",
                    textAlign: "right",
                    color: "#0D152E",
                  },

                  shrink: true,
                }}
              />{" "}
               <TextField
                error={errorFloor}
                helperText={
                  errorFloor
                }
                autoComplete="off"
                onChange={selectFuncFloor}
                value={detailsOfPackageSingel.floor}
                label={t("ADD_DETAILS_WINDOW_INPUT_FLOOR")}
                id="outlined-size-small"
                sx={{
                  width: "24%",
                  height: "81px",
                  marginRight: "5px",
                  "& .MuiOutlinedInput-root": {
                    fontSize: "16px",
                    fontFamily: "Heebo",

                    "& fieldset": {
                      direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                    },
                  },
                }}
                inputProps={{
                  style: {
                    direction:
                      ltrTheme.direction === "ltr" &&
                      isRTL(detailsOfPackageSingel.floor)
                        ? "rtl"
                        : "ltr",
                  },
                }}
                InputLabelProps={{
                  style: {
                    margin: "auto",
                    display: "flex",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "25px",
                    textAlign: "right",
                    color: "#0D152E",
                  },

                  shrink: true,
                }}
              />{" "}
              <TextField
                error={errorEntrance}
                helperText={errorEntrance}
                value={detailsOfPackageSingel.entrance}
                onChange={selectFuncEntrance}
                autoComplete="off"
                label={t("ADD_DETAILS_WINDOW_INPUT_ENTRANCE")}
                sx={{
                  width: "24%",
                  marginRight: "5px",
                  height: "81px",
                  "& .MuiOutlinedInput-root": {
                    fontFamily: "Heebo",
                    fontSize: "16px",
                    "& fieldset": {
                      direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                    },
                  },
                }}
                id="outlined-size-small"
                inputProps={{
                  style: {
                    direction:
                      ltrTheme.direction === "ltr" &&
                      isRTL(detailsOfPackageSingel.entrance)
                        ? "rtl"
                        : "ltr",
                  },
                }}
                InputProps={{ style: InputLabelProps }}
                InputLabelProps={{
                  style: InputLabelProps,
                  shrink: true,
                }}
              />{" "}
            </div>{" "}
          </CacheProvider>{" "}

          {!isExitAddress && (<CacheProvider
            value={
              ltrTheme.direction === "ltr" &&
              isRTL(detailsOfPackageSingel.contactName)
                ? cacheRtl
                : cacheLtr
            }
          >
            
          <div className="costumerDetails_width">
            <TextField
                error={errorName}
                helperText={
                  errorName
                }
                autoComplete="off"
                onChange={selectFuncCostumerName}
                value={detailsOfPackageSingel.contactName}
                label={t("ADD_DETAILS_WINDOW_INPUT_NAME")}
                id="outlined-size-small"
                sx={{
                  width: "48%",
                  height: "81px",
                  marginRight: "5px",
                  "& .MuiOutlinedInput-root": {
                    fontSize: "16px",
                    fontFamily: "Heebo",

                    "& fieldset": {
                      direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                    },
                  },
                }}
                inputProps={{
                  style: {
                    direction:
                      ltrTheme.direction === "ltr" &&
                      isRTL(detailsOfPackageSingel.contactName)
                        ? "rtl"
                        : "ltr",
                  },
                }}
                InputLabelProps={{
                  style: {
                    margin: "auto",
                    display: "flex",
                    fontFamily: "Heebo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "25px",
                    textAlign: "right",
                    color: "#0D152E",
                  },

                  shrink: true,
                }}
              />{" "}
              <TextField
                error={error}
                helperText={
                  error && t("STEPS_NUM_4_ABOUT_YOU_INPUT_NAME_VALIDPHON")
                }
                value={detailsOfPackageSingel.contactPhone}
                onChange={selectFunCcontactPhone}
                autoComplete="off"
                label={t("ADD_DETAILS_WINDOW_INPUT_PHONE")}
                sx={{
                  width: "48%",
                  height: "81px",
                  "& .MuiOutlinedInput-root": {
                    fontFamily: "Heebo",
                    fontSize: "16px",
                    "& fieldset": {
                      direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                    },
                  },
                }}
                id="outlined-size-small"
                inputProps={{
                  style: {
                    direction:
                      ltrTheme.direction === "ltr" &&
                      isRTL(detailsOfPackageSingel.contactPhone)
                        ? "rtl"
                        : "ltr",
                  },
                }}
                InputProps={{ style: InputLabelProps }}
                InputLabelProps={{
                  style: InputLabelProps,
                  shrink: true,
                }}
              />{" "}
            </div>{" "}
          </CacheProvider>)}{" "}
          
          <CacheProvider
            value={
              ltrTheme.direction === "ltr" &&
              isRTL(detailsOfPackageSingel.comment)
                ? cacheRtl
                : cacheLtr
            }
          >
            <TextField
              autoComplete="off"
              onChange={selectFuncComment}
              value={detailsOfPackageSingel.comment}
              label={`${t("ADD_DETAILS_WINDOW_INPUT_REMARKS")}${!isExitAddress ? '' : '/' + t("ADD_DETAILS_WINDOW_INPUT_SHIPPING_CERTIFICATE_NUMBER")}`}
              id="outlined-size-small"
              sx={{
                width: "100%",
                height: "81px",
                "& .MuiOutlinedInput-root": {
                  fontFamily: "Heebo",
                  fontSize: "16px",
                  "& fieldset": {
                    direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                  },
                },
              }}
              inputProps={{
                style: {
                  direction:
                    ltrTheme.direction === "ltr" &&
                    isRTL(detailsOfPackageSingel.comment)
                      ? "rtl"
                      : "ltr",
                },
              }}
              InputLabelProps={{
                style: {
                  margin: "auto",
                  display: "flex",
                  fontFamily: "Heebo",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "25px",
                  textAlign: "right",
                  color: "#0D152E",
                },

                shrink: true,
              }}
            />{" "}
          </CacheProvider>{" "}
        </div>{" "}
      </div>{" "}
      <div className="widthActions">
        <div
          style={{
            display: "flex",
            flexDirection: isMobile && "column",
            alignItems: isMobile && "center",
            justifyContent: isMobile ? "center" : "space-between",
            marginBottom: "20px",
          }}
        >
          <Button
            style={{
              textTransform: "none",
              background: "#583DFF",
              width: isMobile && "100%",
              margin: isMobile && "0px",
            }}
            variant="contained"
            className="saveMoreDetails"
            onClick={saveDetails}
          >
            {" "}
            {t("ADD_DETAILS_WINDOW_BTN_CONFIRM")}{" "}
          </Button>{" "}
          {!isMobile && (
            <Button
              style={{ textTransform: "none" }}
              variant="outlined"
              className="cancelMoreDetails"
              onClick={() => close(detailsOfPackageSingel)}
            >
              {" "}
              {t("ADD_DETAILS_WINDOW_BTN_CANCEL")}{" "}
            </Button>
          )}{" "}
          {isMobile && (
            <div style={{ marginTop: "36px", marginBottom: "33px" }}>
              <img
                className="btn imgMoreDetailsHidden"
                style={{
                  visibility: "visible",
                  cursor: "pointer",
                }}
                onClick={deletePackageSingel}
                src={trash}
                alt="trash"
              />
              <span className="imgMoreDetailsShow">
                {" "}
                {t("DELETE_STATION_MOBILE")}{" "}
              </span>{" "}
            </div>
          )}{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
}
