import {
  CardElement,
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import "../payment/payment.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import { useMediaQuery } from "react-responsive";
import createCache from "@emotion/cache";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import { Button, FormGroup, TextField, CssBaseline } from "@mui/material";
import { t } from "i18next";
import { useContext } from "react";
import React, { useRef, useState, useImperativeHandle } from "react";
import { DataContext } from "../../../../dataContext";
import { useEffect } from "react";
import StripeInput from "./refCardStripe";
import $ from 'jquery';

export default function PaymentForm({
  setErrorBusinnesDetails,
  setAllDetailsofBusinnes,
  allDetailsofBusinnes
}) {
  const [success, setSuccess] = useState(false);
  const [showCardValue, setShowCardValue] = useState(true);
  const ref = useRef();
  // const sumitClass = paymentSumit();
  // const stripe = useStripe();
  // const elements = useElements();
  const inputRef = useRef();
  const {
    errorsCreditCard,
    setErrorsCreditCard,
    update_credit_card,
    setUpdate_credit_card,
    validToken,
    client_secret,
    sumitCusId,
    setClientSecret,
    idCreditCard,
    setIdCreditCard,
    paymentMethodId,
    setPaymentMethodId,
    errorExpiryDate,
    setErrorExpiryDate,
    setUpdateCreditCard,
    isDisabled,
    setIsDisabled
  } = useContext(DataContext);
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const [loading, setLoading] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 650px)" });

  const cacheLtr = createCache({
    key: "muiltr",
  });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });
  // console.log(allDetailsofBusinnes);

  const ltrTheme =
    document.documentElement.dir === "rtl"
      ? createTheme({ direction: "rtl" })
      : createTheme({ direction: "ltr" });
  const InputLabelProps = {
    display: "flex",
    fontFamily: "Heebo",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "25px",
    textAlign: "right",
    color: "#0D152E",
    direction: ltrTheme.direction === "rtl" ? "ltr" : "rtl",
  };
  var sumit_customer_id = null;
  // useEffect(() => {
  //   const handleSubmit = async () => {
  //     // e.preventDefault()
  //     // console.log(e);
  //     // event.preventDefault();
  //     if (!stripe || !elements) {
  //       // Stripe.js has not yet loaded.
  //       // Make sure to disable form submission until Stripe.js has loaded.
  //       // console.log('j');
  //       return;
  //     }
  //     const result = await stripe.confirmCardSetup(client_secret, {
  //       payment_method: {
  //         card: elements.getElement(CardNumberElement),
  //         card: elements.getElement(CardExpiryElement),
  //         card: elements.getElement(CardCvcElement),
  //         billing_details: {
  //           name: allDetailsofBusinnes.fullNameCardOwner
  //             ? allDetailsofBusinnes.fullNameCardOwner
  //             : "",
  //         },
  //       },
  //     });

  //     if (result.error) {
  //       setErrorBusinnesDetails(true);
  //     } else {
  //       console.log(result);
  //       setPaymentMethodId(result.setupIntent.payment_method);
  //     }
  //   };
  //   client_secret && handleSubmit();
  // }, [client_secret]);
  // console.log(client_secret);

  useEffect(() => {
    sumit_customer_id = sumitCusId;
    allDetailsofBusinnes.sumit_customer_id = sumitCusId;
    console.log({
      client_secret: sumitCusId,
    });
  }, [sumitCusId]);
  
  const handleOnSubmit = async (e) => {
    if(e.Data && e.Data.SingleUseToken){
      setLoading(true);
      const data = {
        "Credentials": {
          "CompanyID": 52536134,
          "APIKey": "QgmPSe3W4m6J9ZLMMQXpiE4RfAqAEgQ07AHHa5v74O9WTeBSwf"
        },
        "Customer": {
          "ID": sumit_customer_id,
        },
        "SingleUseToken": e.Data.SingleUseToken
      };
      const result = await axios.post("https://api.sumit.co.il/billing/paymentmethods/setforcustomer/", data);
      console.log(result);
      if (result.data.UserErrorMessage) {
        setErrorBusinnesDetails(true);
        setLoading(false);
      } else {
        console.log(result);
        setLoading(false);
        setPaymentMethodId(result.data.Data.PaymentMethod.ID);
        setUpdateCreditCard(true);
      }
    }else{
      setErrorBusinnesDetails(true);
    }
    
  };
  useEffect(() => {

    // $(async function() {
    //   if(allDetailsofBusinnes.cardNumber && allDetailsofBusinnes.cardNumber.includes('*'))
    //     setIsDisabled(true);
    //   // eslint-disable-next-line
    //   if (OfficeGuy && OfficeGuy.Payments && OfficeGuy.Payments.BindFormSubmit) {
    //     // eslint-disable-next-line
    //     OfficeGuy.Payments.BindFormSubmit({
    //         CompanyID: '52536134',
    //         APIPublicKey: 'jdRfmo0wG4j9TBbdM56QfgVKaEwM7mcn1ktS8P7PkuXmfvyRkO',
    //         ResponseCallback: await handleOnSubmit
    //     });
    //   } else {
    //     console.error('OfficeGuy or BindFormSubmit is not available.');
    //   }
    // });
  }, []);
  const handleChangefullNameCardOwner = (e) => {
    console.log(sumitCusId);
    allDetailsofBusinnes.fullNameCardOwner = e.target.value;
    setAllDetailsofBusinnes({ ...allDetailsofBusinnes });
  };
  const handleChangeId = (e) => {
    allDetailsofBusinnes.id = e.target.value;
    setAllDetailsofBusinnes({ ...allDetailsofBusinnes });
  };
  const switchShowInputComponent = (element) => {
    if (!validToken) {
      return element;
    }
    // if (validToken && showCardValue) {
    //     return
    // }
    if (validToken && !showCardValue) {
      return element;
    }
  };
  const handelOnChangeCardNumber = (e) => {
    if(e.target.value.includes('*'))
      setIsDisabled(true);
    else
      setIsDisabled(false);
    sumit_customer_id = sumitCusId;
    allDetailsofBusinnes.cardNumber = e.target.value;
    setAllDetailsofBusinnes({ ...allDetailsofBusinnes });
    // if(e.target.value) {
    //     errorsCreditCard.empty = true
    //     setErrorsCreditCard({...errorsCreditCard})
    // }

    // console.log(e);
    //console.log(e);
    e.error || e.target.value.includes('*')
      ? (errorsCreditCard.error = true)
      : (errorsCreditCard.error = false);
    e.empty
      ? (errorsCreditCard.empty = true)
      : (errorsCreditCard.empty = false);
    setErrorsCreditCard({ ...errorsCreditCard });
    // if(e.complet){
    setUpdate_credit_card(true);
    // }
    setShowCardValue(false);
  };

  const handelOnChangeExpiryDate = (e) => {
    allDetailsofBusinnes.expiryDate = e.target.value;
    setAllDetailsofBusinnes({ ...allDetailsofBusinnes });
    e.error
      ? (errorsCreditCard.error = true)
      : (errorsCreditCard.error = false);
    e.empty
      ? (errorsCreditCard.empty = true)
      : (errorsCreditCard.empty = false);
    setErrorsCreditCard({ ...errorsCreditCard });

    let month = e.target.value ? Number(e.target.value.slice(0,2)) : undefined;
    let year = e.target.value ? e.target.value.slice(2,4) : undefined;
    if(!e.target.value || e.target.value.length!=4 || month<1 || month>12)
      setErrorExpiryDate(t("ERROR_MESSAGE_EXPIRY_DATE"));
    else
    setErrorExpiryDate(false);
    setShowCardValue(false);
  };
  const handelOnChangeCvc = (e) => {
    allDetailsofBusinnes.cvc = e.target.value;
    setAllDetailsofBusinnes({ ...allDetailsofBusinnes });
    e.error
      ? (errorsCreditCard.error = true)
      : (errorsCreditCard.error = false);
    e.empty && !validToken
      ? (errorsCreditCard.empty = true)
      : (errorsCreditCard.empty = false);
    setErrorsCreditCard({ ...errorsCreditCard });

    setShowCardValue(false);
  };

  return (
//<form onSubmit={handleOnSubmit} data-og="form" className="mobile_width_popUp"
      //style={{ width: "100%", height: "auto", margin: "0 auto", display: "block" }}>
      <>
      <div className="og-errors" style={{display: "none"}}></div>
      <div style={{ width: "100%" }} className="moreDetailsHead">
        {" "}
        <h2
          style={{ textAlign: isMobile && "right", width: "100%" }}
          className="h2HeaderMoreDetails"
        >
          {t("PAYMENT_DETAILS")}
        </h2>
      </div>
      <div style={{ width: "100%" }}>
        <label>
          <input
            type="hidden"
            size="20"
            maxLength="20"
            name="cardNumber"
            data-og="cardnumber"
            value={allDetailsofBusinnes.cardNumber
              ? allDetailsofBusinnes.cardNumber
              : ""} />
        </label>
        <div
          style={{
            marginBottom: !isMobile && "35px",
            height: isMobile && "96px",
          }}
        >
          <CacheProvider
            value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
          >
            <TextField

              placeholder={t("CREDIT_NUM")}
              label={t("CREDIT_NUM")}
              autoComplete="off"
              value={allDetailsofBusinnes.cardNumber
                ? allDetailsofBusinnes.cardNumber
                : ""}
              onChange={handelOnChangeCardNumber}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  fontFamily: "Heebo",
                  fontSize: "16px",
                  height: "71px",
                  "& fieldset": {
                    direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  direction: ltrTheme.direction === "rtl" ? "ltr" : "rtl",
                  textAlign: "left",
                },
              }}
              // inputProps={getCardNumberProps({ onChange: handleChangeCardNumber })}
              //  autoFocus
              InputProps={{
                // startAdornment: <InputAdornment position="start">₪</InputAdornment>,
                style: {
                  width: "100%",
                  height: "71px",
                  fontFamily: "Heebo",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "17px",
                  lineHeight: "25px",
                  textAlign: "right",
                  color: "#0D152E",
                },
              }}
              InputLabelProps={{
                shrink: true,

                style: {
                  margin: "auto",
                  display: "flex",
                  fontFamily: "Heebo",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "25px",
                  textAlign: "right",
                  color: "#0D152E",
                },
              }} />
          </CacheProvider>
        </div>
      </div>
      <input
        type="hidden"
        size="2"
        maxLength="2"
        name="expirationMonth"
        value={allDetailsofBusinnes.expiryDate
          ? allDetailsofBusinnes.expiryDate.slice(0, 2)
          : ""}
        data-og="expirationmonth" />
        <input
        type="hidden"
        size="4"
        maxLength="4"
        name="expirationYear"
        value={allDetailsofBusinnes.expiryDate
          ? allDetailsofBusinnes.expiryDate.slice(2, 4)
          : ""}
        data-og="expirationyear" />
        <div
          className="margin mobile_flex"
          style={{ marginBottom: !isMobile && "25px" }}
        >
        <CacheProvider
          value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
        >
          <TextField
            placeholder={t("EXPIRY_CREDIT")}
            label={t("EXPIRY_CREDIT")}
            maxLength="4"
            error={!!errorExpiryDate}
            helperText={errorExpiryDate ? errorExpiryDate : ""}
            onFocus={() => setErrorExpiryDate(false)}
            value={allDetailsofBusinnes.expiryDate
              ? allDetailsofBusinnes.expiryDate
              : ""}
            onChange={handelOnChangeExpiryDate}
            //  style={{marginBottom:isMobile && "25px"}}
            id="outlined-size-small"
            sx={{
              ml: isMobile ? 0 : 3,
              width: "48%",
              height: !isMobile ? "81px" : "96px",
              "& .MuiOutlinedInput-root": {
                fontFamily: "Heebo",
                fontSize: "16px",
                height: "71px",
                marginBottom: isMobile && "25px",

                "& fieldset": {
                  height: isMobile ? "71px" : "75.99px",
                  direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                },
              },
              "& .MuiOutlinedInput-input": {
                direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                textAlign: "left",
              },
              "& .Mui-error": {
                marginTop: "15px",
              },
            }}
            InputProps={{
              style: {
                height: "71px",
                fontFamily: "Heebo",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "17px",
                lineHeight: "25px",
                textAlign: "right",
                color: "#0D152E",
                flexDirection: "row-reverse",
                direction: ltrTheme.direction === "rtl" && "ltr",
              },
            }}
            InputLabelProps={{
              style: InputLabelProps,
              shrink: true,
            }} />
        </CacheProvider>

        <label>
          <input
            type="hidden"
            size="4"
            maxLength="4"
            name="cvv"
            value={allDetailsofBusinnes.cvc ? allDetailsofBusinnes.cvc : ""}
            data-og="cvv" />
        </label>
        <CacheProvider
          value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
        >
          <TextField
            placeholder={t("3_NUM_CVC")}
            // error={!!error}
            // ref={refInputElement3}
            // helperText={error && t("STEPS_NUM_4_ABOUT_YOU_INPUT_NAME_VALIDPHON")}
            label={t("3_NUM_CVC")}
            // inputProps={getCVCProps({ onChange: handleChangeCvc })}
            value={allDetailsofBusinnes.cvc ? allDetailsofBusinnes.cvc : ""}
            onChange={handelOnChangeCvc}
            // autoComplete='off'
            sx={{
              width: "48%",
              height: !isMobile ? "81px" : "96px",
              "& .MuiOutlinedInput-root": {
                height: "71px",
                fontFamily: "Heebo",
                fontSize: "16px",

                "& fieldset": {
                  direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                },
              },
              "& .MuiOutlinedInput-input": {
                direction: ltrTheme.direction === "rtl" ? "ltr" : "rtl",
                textAlign: "left",
              },
            }}
            id="outlined-size-small"
            // inputProps={
            //     { style: { direction: ltrTheme.direction === "ltr" && isRTL("") ? "rtl" : "ltr" } }}
            InputProps={{
              style: {
                height: "71px",
                fontFamily: "Heebo",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "17px",
                lineHeight: "25px",
                textAlign: "right",
                color: "#0D152E",
                direction: ltrTheme.direction === "ltr" ? "rtl" : "ltr",
              },
            }}
            InputLabelProps={{
              style: InputLabelProps,
              shrink: true,
            }} />
        </CacheProvider>
      </div>
        <label>
          <input
            type="hidden"
            name="citizenId"
            value={allDetailsofBusinnes.id ? allDetailsofBusinnes.id : ""}
            data-og="citizenid" />
        </label>
          <CacheProvider
            value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
          >
            <TextField
              placeholder={t("ID")}
              autoComplete="off"
              onChange={handleChangeId}
              value={allDetailsofBusinnes.id ? allDetailsofBusinnes.id : ""}
              label={t("ID_LABEL")}
              id="outlined-size-small"
              sx={{
                width: "100%",
                height: "81px",
                "& .MuiOutlinedInput-root": {
                  fontFamily: "Heebo",
                  fontSize: "16px",
                  height: "71px",
                  "& fieldset": {
                    direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                  },
                },
              }}
              inputProps={{
                style: {
                  width: "100%",
                  height: "71px",
                  fontFamily: "Heebo",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "17px",
                  lineHeight: "25px",
                  textAlign: "right",
                  color: "#0D152E",
                  direction: ltrTheme.direction === "ltr" ? "rtl" : "ltr",
                },
              }}
              InputLabelProps={{
                style: {
                  margin: "auto",
                  display: "flex",
                  fontFamily: "Heebo",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "25px",
                  textAlign: "right",
                  color: "#0D152E",
                },

                shrink: true,
              }} />
          </CacheProvider>
      {<input type="hidden" name="og-token" id="og-token" />}
      {!loading ? (
            <Button
              type="submit"
              style={{
                fontFamily: "Heebo",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "17px",
                lineHeight: "25px",
                textAlign: "center",
                color: "#FFFFFF",
                height: "62px",
                textTransform: "none",
                background: "#583DFF",
                width:  "100%",
                margin: isMobile && "0px",
              }}
              variant="contained"
              className="saveMoreDetails"
            >
              {t("SAVE_PAYMENT_METHOD")} {"&"} {t("ENTRANCE")}
            </Button>
          ) : (
            <LoadingButton
              variant="contained"
              className="saveMoreDetails"
              sx={{
                height: "62px",
                textTransform: "none",
                background: "#583DFF",
                width : "100%",
                margin: isMobile && "0px",
              }}
              loading={loading}
            ></LoadingButton>
          )}
     {/* </form>
    <div>
      <div
        style={{
          marginBottom: !isMobile && "35px",
          height: isMobile && "96px",
        }}
      >
        <CacheProvider
          value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
        >
          <TextField
          
            placeholder={t("CREDIT_NUM")}
            label={t("CREDIT_NUM")}
            ref={ref}
            value={
              allDetailsofBusinnes.cardNumber
                ? allDetailsofBusinnes.cardNumber
                : ""
            }
            onChange={(e) => handelOnChangeCardNumber(e)}
            sx={{
              maxWidth: "545px",
              width: "100%",
              "& .MuiOutlinedInput-root": {
                fontFamily: "Heebo",
                fontSize: "16px",
                height: "71px",
                "& fieldset": {
                  direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                },
              },
              "& .MuiOutlinedInput-input": {
                direction: ltrTheme.direction === "rtl" ? "ltr" : "rtl",
                textAlign: "left",
              },
            }}
            // inputProps={getCardNumberProps({ onChange: handleChangeCardNumber })}
            //  autoFocus
            InputProps={{
              inputComponent: switchShowInputComponent(StripeInput),
              inputProps: {
                component: switchShowInputComponent(CardNumberElement),
              },
              // startAdornment: <InputAdornment position="start">₪</InputAdornment>,
              style: {
                height: "71px",
                fontFamily: "Heebo",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "17px",
                lineHeight: "25px",
                textAlign: "right",
                color: "#0D152E",
              },
            }}
            InputLabelProps={{
              shrink: true,

              style: {
                margin: "auto",
                display: "flex",
                fontFamily: "Heebo",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "25px",
                textAlign: "right",
                color: "#0D152E",
              },
            }}

            // inputRef={getCardNumberProps().ref}
          />
        </CacheProvider>
      </div>

      <div
        className="margin mobile_flex"
        style={{ marginBottom: !isMobile && "25px" }}
      >
        <CacheProvider
          value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
        >
          <TextField
            placeholder={t("EXPIRY_CREDIT")}
            label={t("EXPIRY_CREDIT")}
            // inputProps={getExpiryDateProps({ onChange: handleChangeExpiryDate })}
            value={
              allDetailsofBusinnes.expiryDate
                ? allDetailsofBusinnes.expiryDate
                : ""
            }
            onChange={(e) => handelOnChangeExpiryDate(e)}
            //  style={{marginBottom:isMobile && "25px"}}
            id="outlined-size-small"
            sx={{
              ml: isMobile ? 0 : 3,
              width: "48%",
              height: !isMobile ? "81px" : "96px",
              "& .MuiOutlinedInput-root": {
                fontFamily: "Heebo",
                fontSize: "16px",
                height: "71px",
                marginBottom: isMobile && "25px",

                "& fieldset": {
                  height: isMobile ? "71px" : "75.99px",
                  direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                },
              },
              "& .MuiOutlinedInput-input": {
                direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                textAlign: "left",
              },
            }}
            InputProps={{
              inputComponent: switchShowInputComponent(StripeInput),
              inputProps: {
                component: switchShowInputComponent(CardExpiryElement),
              },
              // startAdornment: <InputAdornment position="start">₪</InputAdornment>,
              style: {
                height: "71px",
                fontFamily: "Heebo",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "17px",
                lineHeight: "25px",
                textAlign: "right",
                color: "#0D152E",
                flexDirection: "row-reverse",
                direction: ltrTheme.direction === "rtl" && "ltr",
              },
            }}
            InputLabelProps={{
              style: InputLabelProps,
              shrink: true,
            }}
          />
        </CacheProvider>
        <CacheProvider
          value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
        >
          <TextField
            placeholder={t("3_NUM_CVC")}
            // error={!!error}
            // ref={refInputElement3}
            // helperText={error && t("STEPS_NUM_4_ABOUT_YOU_INPUT_NAME_VALIDPHON")}
            label={t("3_NUM_CVC")}
            // inputProps={getCVCProps({ onChange: handleChangeCvc })}
            value={allDetailsofBusinnes.cvc ? allDetailsofBusinnes : ""}
            onChange={(e) => handelOnChangeCvc(e)}
            // autoComplete='off'

            sx={{
              width: "48%",
              height: !isMobile ? "81px" : "96px",
              "& .MuiOutlinedInput-root": {
                height: "71px",
                fontFamily: "Heebo",
                fontSize: "16px",

                "& fieldset": {
                  direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                },
              },
              "& .MuiOutlinedInput-input": {
                direction: ltrTheme.direction === "rtl" ? "ltr" : "rtl",
                textAlign: "left",
              },
            }}
            id="outlined-size-small"
            // inputProps={
            //     { style: { direction: ltrTheme.direction === "ltr" && isRTL("") ? "rtl" : "ltr" } }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardCvcElement,
              },
              style: {
                height: "71px",
                fontFamily: "Heebo",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "17px",
                lineHeight: "25px",
                textAlign: "right",
                color: "#0D152E",
                direction: ltrTheme.direction === "ltr" ? "rtl" : "ltr",
              },
            }}
            InputLabelProps={{
              style: InputLabelProps,
              shrink: true,
            }}
          />
        </CacheProvider>
      </div>
    </div> */}
    </>
  );
}
