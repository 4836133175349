import "./detailsOfPackage.css";
import Arrow from "../images/Arrow.png";
import trash from "../images/Trash.png";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import rtlPlugin from "stylis-plugin-rtl";
import { CssBaseline } from "@mui/material";
import createCache from "@emotion/cache";
import PopUpMoreDetails from "./MultiSteps/stepsNum/popUpDetails_responsive";
import PopUpForDex from "./MultiSteps/stepsNum/popUp";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import ArrowDown from "../images/arrowDown.png";
import BusinessDetailsPackage from "./businessDetailsPackage/businessDetailsPackage";
import axios from "axios";
import {
  MenuItem,
  Select,
  Alert,
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import React, { useRef, useState, useEffect, useContext } from "react";
import LocationSearchInput from "./reactAutoComplete";
import MultiSteps from "./MultiSteps/MultiSteps";
import { useMediaQuery } from "react-responsive";
import { DataContext } from "../../../dataContext";
import moment from "moment";
import { useParams } from "react-router-dom";
import "./MultiSteps/stepsNum/stepsNumCss/address_step.css";
import { injectGlobal } from "@emotion/css";
import dayjs, { Dayjs } from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

//import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TimePicker from "@mui/lab/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import SearchLocation from "./searchLocation";
// import Select from "react-select";

export default function DetailsOfPackage({ showSteps, setShowSteps }) {
  const { t,i18n } = useTranslation();
  const { token } = useParams();
  const labelText = t("DETAILS_OF_PACKAGE_INPUT_ADDRESS")+"("+t("DETAILS_OF_PACKAGE_INPUT_ADDRESS_LABEL")+")";
  const [addressError,setAddressError] = useState(false);
  const pickUpOptions = [
    { value: "1", label: t("COLLECTION_OPTION1") },
    { value: "2", label: t("COLLECTION_OPTION2") },
    { value: "3", label: t("COLLECTION_OPTION3") },
    { value: "4", label: t("COLLECTION_OPTION4") },
  ];

  const deliveryOptions = [
    { value: "1", label: t("DELIVERY_OPTION1") },
    { value: "2", label: t("DELIVERY_OPTION2") },
    { value: "3", label: t("DELIVERY_OPTION3") },
    { value: "4", label: t("DELIVERY_OPTION4") },
  ];

  const {
    businessCostumerConnect,
    responseGetById,
    setResponseGetById,
    exitAddress,
    setExitaddress,
    errorAutoComplet,
    setErrorAutoComplet,
    getDataFromServer,
    setGetDataFromServer,
    errorDDline,
    setErrorDDline,
    errorPickUp,
    setErrorPickUp,
    address,
    setAddress,
    detailsOfPackage,
    setDetailsOfPackage,
    time,
    setTime,
    nameAndPhone,
    setNameAndPhone,
    checkboxValue,
    setCheckboxValue,
    activeStep,
    setActiveStep,
    validToken,
    allDetailsofBusinnes,
    setSumitCusId,
    paymentMethodId,
    setPaymentMethodId,
    setTestUser,
    setIsGuest
  } = useContext(DataContext);

  const now = new Date();
  now.setMinutes(now.getMinutes() + 1 - now.getTimezoneOffset());
  const ddL = new Date();
  ddL.setHours(ddL.getHours() + 15);

  const ref = useRef();
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 650px)" });
  const Tablet = useMediaQuery({ query: "(max-width: 800px)" });
  const [optionPickUpTime, setOptionPickUpTime] = useState("1");
  const [optionDDlineTime, setOptionDDlineTime] = useState("3");
  const [showListOption, setShowListOption] = useState(null);
  const [singelPoint, setSingelPoint] = useState("");
  const [openMoreDetailsPopUp,setOpenMoreDetailsPopUp] = useState(false);


  const handleOpenMenu = (value) => {
    setExitaddress((value) => value);
    setTimeout(() => {
      setOpenMoreDetailsPopUp(openMoreDetailsPopUp ? false : true);
      setSingelPoint("");
    }, 100); 
  };

  const handleCloseMenu = (singelPackage) => {

    setExitaddress(exitAddress);

    setTimeout(() => {
      setOpenMoreDetailsPopUp(false);

      // singelPackage.apartment_number = "";
      // singelPackage.comment = "";
      // singelPackage.floor = "";
      // singelPackage.entrance = "";
    }, 100);
    
  };

  const showDetails = (_exitAddress) => {
    setOpenMoreDetailsPopUp(true);
    _exitAddress.destination = address.address;
    setSingelPoint(_exitAddress);
  };



  useEffect(() => {
    time.ddLine = optionDDlineTime;
    time.pickUpTime = optionPickUpTime;
  }, []);

  useEffect(() => {
    if(!exitAddress.streetNum || !exitAddress.streetNum.length || !exitAddress.streetName || !exitAddress.streetName.length || !exitAddress.cityName || !exitAddress.cityName.length)
      setAddressError(true);
    else
    setAddressError(false);
    // if (!openMoreDetailsPopUp && (!showSteps||!activeStep))//exitAddress.lat && exitAddress.lng &&   CANCELLED-Always open popup 
    //   showDetails(exitAddress);
  }, [exitAddress]);

  useEffect(() => {
    const getDetails = async ()=> {
      try {
        const result = await axios.get(
          "/pogo_connect_api/server/web/index.php/customers/get_details",
          {
            headers: {
              Authorization: `Bearer ${validToken.access_token}`,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        );
        if(result.data.is_guest)
            setIsGuest(true);
        if(result.data.stripe_id) {
          let cus_id = parseInt(result.data.stripe_id);
          setSumitCusId(cus_id);
        }
        if(result.data.stripe_payment_method_id && !paymentMethodId)
          setPaymentMethodId(result.data.stripe_payment_method_id);

        setExitaddress({
          cityName: result.data.business_address.locality_long,
          streetName: result.data.business_address.route_long,
          streetNum: result.data.business_address.street_number_long,
          lat: result.data.business_address.lat,
          lng: result.data.business_address.lng,
          floor: result.data.floor ? result.data.floor : "",
          apartment_number: result.data.apartment_number ? result.data.apartment_number  : "",
          entrance: result.data.entrance ? result.data.entrance : ""
        });
        setAddress({
          address: result.data.business_address.formatted_address,
        });
        
      } catch (error) {
        console.log(error);
      }
    }
    if (token && !responseGetById) {
      // console.log(showSteps);
      setShowSteps(true);
    }
    if(validToken){
      getDetails();
    }
    
  }, [validToken]);

  const ltrTheme =
    document.documentElement.dir === "rtl"
      ? createTheme({ direction: "rtl" })
      : createTheme({ direction: "ltr" });

  const cacheLtr = createCache({
    key: "muiltr",
  });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });
  // console.log("language: " + ltrTheme.direction);

  const InputLabelProps = {
    fontFamily: "Heebo",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "right",
    color: "#4F5976",
  };

  const startSteps = (e) => {
    e.preventDefault();
    if(exitAddress.lat == "" && exitAddress.lng == ""){
      showDetails(exitAddress);
      return 0;
    }
    if (
      exitAddress.cityName == "" &&
      exitAddress.streetName == "" &&
      exitAddress.streetNum == "" 
    ) {
      return setErrorAutoComplet(t("ERROR_MESSAGE_AUTOCOMPLET"));
    }

    if (!errorAutoComplet) {
      setShowSteps(true);
    }
  };

  const focusClearErrors = () => {
    setErrorPickUp(false);
    setErrorDDline(false);
    setErrorAutoComplet(false);
  };
  //const [value, setValue] = (useState < Dayjs) | (null > dayjs());
  //const [value, setValue] = (React.useState < Dayjs) | (null > dayjs());
  // const [valuee, setValuee] =
  //   (React.useState < Dayjs) | (null > dayjs("2018-01-01T00:00:00.000Z"));

  const changeListPickUp = (e) => {
    console.log(e.target.value);
    if (e.target.value !== "") {
      setOptionPickUpTime(e.target.value);

      time.pickUpTime = e.target.value;
    }
  };
  const changeListDelivery = (e) => {
    if (e.target.value !== "") {
      setOptionDDlineTime(e.target.value);

      time.ddLine = e.target.value;
    }
  };

  const changePickUpTime = (e) => {
    if (isTabletOrMobile) {
      //old code - without date poup in desktop
      if (e.target.value !== "") {
        time.pickUpTime = e.target.value;
        time.ddLine = moment(new Date(time.pickUpTime))
          .add(1, "days")
          .format("YYYY-MM-DD[T]HH:mm");
        setTime({ ...time });
      } else {
        time.pickUpTime = now.toISOString().substring(0, 16);
        time.ddLine = moment().add(1, "days").format("YYYY-MM-DD[T]HH:mm");
        setTime({ ...time });
      }
    } else {
      console.log(e);
      console.log(e["$d"]);
      if (e["$d"] !== "") {
        time.pickUpTime = moment(new Date(e["$d"])).format(
          "YYYY-MM-DD[T]HH:mm"
        );
        time.ddLine = moment(new Date(time.pickUpTime))
          .add(1, "days")
          .format("YYYY-MM-DD[T]HH:mm");
        setTime({ ...time });
        console.log(time);
      } else {
        time.pickUpTime = now.toISOString().substring(0, 16);
        time.ddLine = moment().add(1, "days").format("YYYY-MM-DD[T]HH:mm");
        setTime({ ...time });
      }
    }
  };
  const checkOnFocus = (e) => {
    time.pickUpTime != e.target.value && setErrorPickUp("");
    setErrorDDline("");
  };

  const changeDdLine = (e) => {
    if (isTabletOrMobile) {
      // old
      time.ddLine = e.target.value;
      setTime({ ...time });
    } else {
      time.ddLine = moment(new Date(e["$d"])).format("YYYY-MM-DD[T]HH:mm");
      console.log(e);
      console.log(time.ddLine);
      setTime({ ...time });
    }
  };

  let height;

  function styleHeight() {
    height = "";
    if (isMobile) {
      if (errorAutoComplet || errorDDline || errorPickUp) {
        return (height += "600px");
      }
    }
  }

  const heightDetails = () => {
    let heightDetails = "";
    if (activeStep == 1 && isMobile) {
      return (heightDetails = "985px");
    }
    if (isMobile && showSteps && activeStep < 5) {
      return (heightDetails = "821px");
    }

    if (activeStep == 1 && !isTabletOrMobile) {
      return (heightDetails = "850px");
    }

    if (isMobile && activeStep == 5) {
      return (heightDetails = "745px");
    }
    if (isMobile && !showSteps) {
      return (heightDetails = "531px");
    }
    if (isTabletOrMobile && showSteps && activeStep < 5) {
      return (heightDetails = "686px");
    }

    if (isTabletOrMobile && activeStep == 5) {
      return (heightDetails = "585px");
    }
    if (isTabletOrMobile && !showSteps) {
      return (heightDetails = "645px");
    }
    if ((Tablet && !showSteps) || (activeStep == 5 && Tablet)) {
      return (heightDetails = "592px");
    }
    if (validToken && showSteps) {
      return (heightDetails = "1312px");
    }
    if (Tablet && showSteps) {
      return (heightDetails = "675px");
    } else {
      return (heightDetails = "809px");
    }
  };
  //
  const switchPaddingLefInput = () => {
    if (ltrTheme.direction === "rtl" && isMobile) {
      return "25px";
    }
  };

  const selectFuncStreetName = (e) => {
    const newExitAddress = {
      ...exitAddress,
      streetName: e.target.value
    };
    setExitaddress(newExitAddress);  
  };

  const selectFuncStreetNum = (e) => {
    const newExitAddress = {
      ...exitAddress,
      streetNum: e.target.value
    };
    setExitaddress(newExitAddress);  
  };

  const selectFuncCityName = (e) => {
    const newExitAddress = {
      ...exitAddress,
      cityName: e.target.value
    };
    setExitaddress(newExitAddress);  
  };

  return openMoreDetailsPopUp && isMobile ? (
    <div className="flex-popup">
      <PopUpMoreDetails
        labelDestination={t(`STEPS_ADDRESS_DELIVERY`)}
        click={handleOpenMenu}
        open={openMoreDetailsPopUp}
        selected={(address) =>{
          setExitaddress(address);
          if(address.destination && address.destination != "")
            setAddress({address:address.destination});
          if(address?.comment && address.comment == 'test3631')
            setTestUser(true);
        }}
        AllDetailsOfPackage={exitAddress}
        close={handleCloseMenu}
        detailsOfPackageSingel={exitAddress}
        className="moreDetailsWindow"
        isExitAddress={true}
        setAddress={(address)=>{
          setAddress({address:address})
        }}
        errorAutoComplet={errorAutoComplet}
        setErrorAutoComplet={setErrorAutoComplet}
    />
    </div>
    
  ):(
    <section
      className="container-fluid mobile_details_height"
      style={{ height: heightDetails(), background: !showSteps && "#F1F1F6" }}
    >
      <div className="cover">
        <div className="coverDetails" style={{ height: styleHeight() }}>
          {" "}
          {!showSteps ? (
            <div style={{ position: "relative" }}>
              <div className="details mobile_details">
                <div className="flex_header_details">
                  <h1 className="h1_mobile">
                    {" "}
                    {t("DETAILS_OF_PACKAGE_LINE_1")}{" "}
                  </h1>{" "}
                  <p className="p_details_mobile">
                    {" "}
                    {t("DETAILS_OF_PACKAGE_INPUT_ADDRESS")}{" "}
                  </p>{" "}
                </div>{" "}
                <div className="mobile_width_detailofpackage">
                  <Stack onFocus={focusClearErrors}>
                    <div
                      style={{
                        marginBottom: isMobile && "0px",
                        height: isTabletOrMobile && !isMobile && "111px",
                      }}
                    >
                    { address.address != null && (
                      ((!exitAddress.lat&&!exitAddress.lng)) ? (
                        // <LocationSearchInput
                        //   errorAutoComplet={errorAutoComplet}
                        //   setErrorAutoComplet={setErrorAutoComplet}
                        //   exitAddress={exitAddress}
                        //   setExitaddress={setExitaddress}
                        //   label={t("DETAILS_OF_PACKAGE_INPUT_ADDRESS")}
                        //   value={address.address}
                        //   onChange={(add) => setAddress({ address: add })}
                        // />
                        <SearchLocation
                        address={exitAddress}
                        selectFuncCityName={selectFuncCityName}
                        selectFuncStreetNum={selectFuncStreetNum}
                        selectFuncStreetName={selectFuncStreetName}
                        errorAutoComplet={errorAutoComplet}
                        />
                      ):(
                      <div className="overflow">
                        <div id="1" key="0" style={{
                            direction:document.documentElement.dir === "rtl" ? "ltr" : "rtl",
                            height: "150px"
                          }}>
                        <div
                          id="0"
                          style={{
                            position: "relative",
                          }}
                          className="deliveryAddress"
                        >
                          <p
                            style={{
                              position: "absolute",
                              top: "-10px",
                              right:
                                document.documentElement.dir === "rtl" ? "13px" : "",
                              fontSize: "14px",
                              color: "rgb(79, 89, 118)",
                            }}
                          >
                            {" "}
                            {labelText}
                          </p>
                          <fieldset
                            aria-hidden="true"
                            className={
                              document.documentElement.dir === "ltr"
                                ? "muirtl-1d3z3hw-MuiOutlinedInput-notchedOutline"
                                : "muiltr-1d3z3hw-MuiOutlinedInput-notchedOutline"
                            }
                          >
                            <legend
                              style={{
                                fontSize:
                                  document.documentElement.dir === "rtl" && "16px",
                              }}
                              className={`${
                                document.documentElement.dir === "ltr"
                                  ? "muirtl"
                                  : "muiltr"
                              }-1in441m`}
                            >
                            </legend>
                          </fieldset>
                          <div className="overflow_point">
                            <div className="overflow_point_hidden">
                              <span className="span_destination">
                                {address.address}
                              </span>{" "}
                              <br />
                            </div>
                            {exitAddress.apartment_number !== "" ||
                            exitAddress.floor !== "" ||
                            exitAddress.entrance !== ""  ? (
                              <div>
                                <span className="pointClass">
                                  {t("ADD_DETAILS_WINDOW_INPUT_APARTMENT")}{" "}
                                  {exitAddress.apartment_number
                                    ? `${exitAddress.apartment_number},`
                                    : ""}{" "}
                                  {t("ADD_DETAILS_WINDOW_INPUT_FLOOR")}{" "}{exitAddress.floor ? exitAddress.floor : ""}{" "}
                                  {t("ADD_DETAILS_WINDOW_INPUT_ENTRANCE")}{" "}{exitAddress.entrance
                                    ? exitAddress.entrance
                                    : ""}{" "}
                                </span>
                              </div>
                            ) : null}{" "}
                          </div>
                          <div
                            onClick={() => 
                              isMobile &&
                              setShowListOption(showListOption === 0 ? null : 0)
                            }
                            className="details_btn"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <div className={
                              isMobile && showListOption === 0
                                ? "div_listOption_mobile"
                                : "div_listOption"
                            }>
                              <span
                              className={`${
                                isMobile && showListOption !== 0
                                  ? "btn moreDetailsHiiden"
                                  : "moreDetails"
                              }`}
                                style={{ cursor: "pointer" }}
                                onClick={() => showDetails(exitAddress)}
                              >
                                {exitAddress.apartment_number !== "" ||
                                exitAddress.floor !== "" ||
                                exitAddress.comment !== "" ||
                                exitAddress.entrance !== ""
                                  ? t("ADD_DETAILS_EDIT")
                                  : t("ADD_DETAILS_LINK")}
                              </span>
                              <div>
                                <img
                                  className={`${
                                    isMobile &&
                                    showListOption !== 0 &&
                                    "btn imgMoreDetailsHidden"
                                  }`}
                                  style={{
                                    cursor: "pointer",
                                    position:
                                      isMobile && showListOption === 0
                                        ? "absolute"
                                        : "",
                                    top: "70px",
                                    right:
                                      document.documentElement.dir === "ltr"
                                        ? "12px"
                                        : "0px",
                                    left:
                                      document.documentElement.dir === "rtl"
                                        ? "8px"
                                        : "0px",
                                  }}
                                  onClick={() => {
                                    setExitaddress(
                                      {
                                        cityName: "",
                                        streetName: "",
                                        streetNum: "",
                                        apartment_number: "",
                                        floor: "",
                                        entrance: "",
                                        lat: "",
                                        lng: "",
                                      }
                                    );
                                  }}
                                  src={trash}
                                  alt="trash"
                                />
                                  {showListOption === 0 && isMobile ? (
                                    <span className="imgMoreDetailsShow">
                                      {t("DELETE_STATION_MOBILE")}
                                    </span>
                                  ) : null}

                              </div>
                            </div>
                          </div>
                                        
                        </div>
                        {addressError && <span className="address-error">{t("DETAILS_OF_PACKAGE_INPUT_ADDRESS_LABEL")}</span>} 
                      </div>
                    </div>
                      )
                      
                    )}{" "}
                      {/*הוספת פרטים למחשב popUp  */}
                      {openMoreDetailsPopUp && !isMobile ? (
                        <PopUpForDex
                          labelDestination={t(`STEPS_ADDRESS_DELIVERY`)}
                          click={handleOpenMenu}
                          open={openMoreDetailsPopUp}
                          selected={(address) =>{
                            setExitaddress(address);
                            if(address.destination && address.destination != "")
                              setAddress({address:address.destination});
                            if(address?.comment && address.comment == 'test3631')
                              setTestUser(true);
                          }}
                          AllDetailsOfPackage={exitAddress}
                          close={handleCloseMenu}
                          detailsOfPackageSingel={exitAddress}
                          setAddress={(address)=>{
                            setAddress({address:address})
                          }}
                          errorAutoComplet={errorAutoComplet}
                          setErrorAutoComplet={setErrorAutoComplet}
                          className="moreDetailsWindow"
                          isExitAddress={true}
                        />
                      ) : null}
                    </div>{" "}
                    <div
                      className="flex_date_time"
                      style={{
                        // visibility: !isTabletOrMobile ? "visible" : "hidden",
                        display: isTabletOrMobile ? "block" : "flex",
                        marginTop: isMobile
                          ? "60px"
                          : isTabletOrMobile
                          ? "0px"
                          : "60px",
                        marginBottom: isMobile
                          ? "45px"
                          : isTabletOrMobile
                          ? "0px"
                          : "65px",
                      }}
                    >
                      {/* <CacheProvider
                        value={
                          // old - before calendar
                          ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr
                        }
                      >
                        <CssBaseline /> */}
                        {/* <TextField
                          id="outlined-select-currency"
                          select
                          SelectProps={{
                            IconComponent: () => (
                              <img
                                src={ArrowDown}
                                alt="arrow_down"
                                style={{ marginLeft: "7px", cursor: "pointer" }}
                              />
                            ),
                          }}
                          style={{
                            direction:
                              ltrTheme.direction === "rtl" ? "ltr" : "rtl",
                          }}
                          sx={{
                            "& .MuiFormHelperText-root": {
                              fontFamily: "heebo",
                            },
                            width: isMobile
                              ? 300
                              : isTabletOrMobile
                              ? "100%"
                              : "48%",
                            marginBottom: isMobile
                              ? "23px"
                              : isTabletOrMobile && "35px",
                            "& .MuiOutlinedInput-root": {
                              fontSize: "16px",
                              fontFamily: "heebo",
                              paddingLeft: switchPaddingLefInput(),
                            },
                            "& fieldset": {
                              direction:
                                ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                            },
                          }}
                          // defaultValue = {pickUpOptions[0].label}
                          InputLabelProps={{
                            style: InputLabelProps,
                            shrink: true,
                          }}
                          inputProps={{
                            style: {
                              textAlign:
                                ltrTheme.direction === "ltr" ? "right" : "left",
                              display:
                                ltrTheme.direction === "ltr" ? "flex" : "block",
                              flexDirection:
                                ltrTheme.direction === "ltr"
                                  ? "row-reverse"
                                  : "row",
                              height: isMobile
                                ? "40px"
                                : isTabletOrMobile && "40px",
                              alignItems:
                                ltrTheme.direction === "rtl" &&
                                isMobile &&
                                "flex-end",
                            },
                          }}
                          label={t("DETAILS_OF_PACKAGE_INPUT_DATE")}
                          width={300}
                          value={optionPickUpTime}
                          onChange={changeListPickUp}
                        >
                          {pickUpOptions.map((option) => (
                            <MenuItem
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField> */}

                        {/* <TextField
                          id="outlined-select-currency"
                          select
                          SelectProps={{
                            IconComponent: () => (
                              <img
                                src={ArrowDown}
                                alt="arrow_down"
                                style={{ marginLeft: "7px", cursor: "pointer" }}
                              />
                            ),
                          }}
                          style={{
                            direction:
                              ltrTheme.direction === "rtl" ? "ltr" : "rtl",
                          }}
                          sx={{
                            "& .MuiFormHelperText-root": {
                              fontFamily: "heebo",
                            },
                            width: isMobile
                              ? 300
                              : isTabletOrMobile
                              ? "100%"
                              : "48%",
                            marginBottom: isMobile
                              ? "23px"
                              : isTabletOrMobile && "35px",
                            "& .MuiOutlinedInput-root": {
                              fontSize: "16px",
                              fontFamily: "heebo",
                              paddingLeft: switchPaddingLefInput(),
                            },
                            "& fieldset": {},
                          }}
                          InputLabelProps={{
                            style: InputLabelProps,
                            shrink: true,
                          }}
                          inputProps={{
                            style: {
                              // "& .MuiSelect-select":{
                              direction:
                                ltrTheme.direction === "rtl" ? "ltr" : "rtl",
                              // }
                              textAlign:
                                ltrTheme.direction === "ltr" ? "right" : "left",
                              display:
                                ltrTheme.direction === "ltr" ? "flex" : "block",
                              flexDirection:
                                ltrTheme.direction === "ltr"
                                  ? "row-reverse"
                                  : "row",
                              height: isMobile
                                ? "40px"
                                : isTabletOrMobile && "40px",
                              alignItems:
                                ltrTheme.direction === "rtl" &&
                                isMobile &&
                                "flex-end",
                            },
                          }}
                          label={t("DETAILS_OF_PACKAGE_DDLINE")}
                          width={300}
                          value={optionDDlineTime}
                          onChange={changeListDelivery}
                        >
                          {deliveryOptions.map((option) => (
                            <MenuItem
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </CacheProvider>{" "} */}
                    </div>
                    <Button
                      onClick={startSteps}
                      className="marginBtn"
                      style={{
                        height: "62px",
                        margin: "auto",
                        backgroundColor: "#583DFF",
                        borderRadius: "4px",
                      }}
                      variant="contained"
                    >
                      {" "}
                      <img className="Arrow" src={Arrow} alt="Arrow" />{" "}
                      {t("DETAILS_OF_PACKAGE_CONTINUE")}{" "}
                    </Button>{" "}
                  </Stack>{" "}
                </div>{" "}
              </div>{" "}
            </div>
            
          ) : (
            <div
              className="details MultiSteps"
              style={{ height: isMobile && activeStep == 5 && "700px" }}
            >
              <MultiSteps />
            </div>
          )}{" "}
        </div>{" "}
      </div>{" "}
    </section>
  );
}

//old code
// import "./detailsOfPackage.css";
// import Arrow from "../images/Arrow.png";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
// import { CacheProvider } from "@emotion/react";
// import rtlPlugin from "stylis-plugin-rtl";
// import { CssBaseline } from "@mui/material";
// import createCache from "@emotion/cache";
// import Stack from "@mui/material/Stack";
// import TextField from "@mui/material/TextField";
// import { useTranslation } from "react-i18next";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
// import {
//   Alert,
//   Autocomplete,
//   Button,
//   IconButton,
//   InputAdornment,
// } from "@mui/material";
// import React, { useRef, useState, useEffect, useContext } from "react";
// import LocationSearchInput from "./reactAutoComplete";
// import MultiSteps from "./MultiSteps/MultiSteps";
// import { useMediaQuery } from "react-responsive";
// import { DataContext } from "../../../dataContext";
// import moment from "moment";
// import { useParams } from "react-router-dom";
// import { injectGlobal } from "@emotion/css";
// import dayjs, { Dayjs } from "dayjs";

// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// //import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import TimePicker from "@mui/lab/TimePicker";
// import { DateTimePicker } from "@mui/x-date-pickers";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import MobileDatePicker from "@mui/lab/MobileDatePicker";

// export default function DetailsOfPackage({ showSteps, setShowSteps }) {
//   const { t } = useTranslation();
//   const { token } = useParams();

//   const {
//     responseGetById,
//     setResponseGetById,
//     exitAddress,
//     setExitaddress,
//     errorAutoComplet,
//     setErrorAutoComplet,
//     getDataFromServer,
//     setGetDataFromServer,
//     errorDDline,
//     setErrorDDline,
//     errorPickUp,
//     setErrorPickUp,
//     address,
//     setAddress,
//     detailsOfPackage,
//     setDetailsOfPackage,
//     time,
//     setTime,
//     nameAndPhone,
//     setNameAndPhone,
//     checkboxValue,
//     setCheckboxValue,
//     activeStep,
//     setActiveStep,
//   } = useContext(DataContext);

//   const now = new Date();
//   now.setMinutes(now.getMinutes() + 1 - now.getTimezoneOffset());
//   const ddL = new Date();
//   ddL.setHours(ddL.getHours() + 15);

//   const ref = useRef();
//   const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
//   const isTabletOrMobile = useMediaQuery({ query: "(max-width: 650px)" });
//   const Tablet = useMediaQuery({ query: "(max-width: 800px)" });

//   useEffect(() => {
//     if (token && !responseGetById) {
//       console.log(showSteps);
//       // console.log('f');
//       setShowSteps(true);
//     }
//   }, []);

//   const ltrTheme =
//     document.documentElement.dir === "rtl"
//       ? createTheme({ direction: "rtl" })
//       : createTheme({ direction: "ltr" });
//   const cacheLtr = createCache({
//     key: "muiltr",
//   });
//   const cacheRtl = createCache({
//     key: "muirtl",
//     stylisPlugins: [rtlPlugin],
//   });
//   console.log("language: " + ltrTheme.direction);

//   const InputLabelProps = {
//     fontFamily: "Heebo",
//     fontStyle: "normal",
//     fontWeight: "500",
//     fontSize: "16px",
//     lineHeight: "22px",
//     textAlign: "right",
//     color: "#4F5976",
//   };

//   // console.log(time);

//   const startSteps = (e) => {
//     e.preventDefault();
//     // console.log(exitAddress);
//     if (
//       exitAddress.cityName == "" &&
//       exitAddress.streetName == "" &&
//       exitAddress.streetNum == ""
//     ) {
//       return setErrorAutoComplet(t("ERROR_MESSAGE_AUTOCOMPLET"));
//     }

//     if (!errorAutoComplet) {
//       setShowSteps(true);
//     }
//   };

//   const focusClearErrors = () => {
//     setErrorPickUp(false);
//     setErrorDDline(false);
//     setErrorAutoComplet(false);
//   };
//   //const [value, setValue] = (useState < Dayjs) | (null > dayjs());
//   //const [value, setValue] = (React.useState < Dayjs) | (null > dayjs());
//   // const [valuee, setValuee] =
//   //   (React.useState < Dayjs) | (null > dayjs("2018-01-01T00:00:00.000Z"));

//   const changePickUpTime = (e) => {
//     if (isTabletOrMobile) {
//       //old code - without date poup in desktop
//       if (e.target.value !== "") {
//         time.pickUpTime = e.target.value;
//         time.ddLine = moment(new Date(time.pickUpTime))
//           .add(1, "days")
//           .format("YYYY-MM-DD[T]HH:mm");
//         setTime({ ...time });
//       } else {
//         time.pickUpTime = now.toISOString().substring(0, 16);
//         time.ddLine = moment().add(1, "days").format("YYYY-MM-DD[T]HH:mm");
//         setTime({ ...time });
//       }
//     } else {
//       console.log(e);
//       console.log(e["$d"]);
//       if (e["$d"] !== "") {
//         time.pickUpTime = moment(new Date(e["$d"])).format(
//           "YYYY-MM-DD[T]HH:mm"
//         );
//         time.ddLine = moment(new Date(time.pickUpTime))
//           .add(1, "days")
//           .format("YYYY-MM-DD[T]HH:mm");
//         setTime({ ...time });
//         console.log(time);
//       } else {
//         time.pickUpTime = now.toISOString().substring(0, 16);
//         time.ddLine = moment().add(1, "days").format("YYYY-MM-DD[T]HH:mm");
//         setTime({ ...time });
//       }
//     }
//   };
//   const checkOnFocus = (e) => {
//     time.pickUpTime != e.target.value && setErrorPickUp("");
//     setErrorDDline("");
//   };

//   const changeDdLine = (e) => {
//     if (isTabletOrMobile) {
//       // old
//       time.ddLine = e.target.value;
//       setTime({ ...time });
//     } else {
//       time.ddLine = moment(new Date(e["$d"])).format("YYYY-MM-DD[T]HH:mm");
//       console.log(e);
//       console.log(time.ddLine);
//       setTime({ ...time });
//     }
//   };

//   let height;

//   function styleHeight() {
//     height = "";
//     if (isMobile) {
//       if (errorAutoComplet || errorDDline || errorPickUp) {
//         return (height += "600px");
//       }
//     }
//   }

//   const heightDetails = () => {
//     let heightDetails = "";
//     if (isMobile && showSteps && activeStep < 5) {
//       return (heightDetails = "821px");
//     }
//     if (isMobile && activeStep == 5) {
//       return (heightDetails = "745px");
//     }
//     if (isMobile && !showSteps) {
//       return (heightDetails = "531px");
//     }
//     if (isTabletOrMobile && showSteps && activeStep < 5) {
//       return (heightDetails = "686px");
//     }

//     if (isTabletOrMobile && activeStep == 5) {
//       return (heightDetails = "585px");
//     }
//     if (isTabletOrMobile && !showSteps) {
//       return (heightDetails = "645px");
//     }
//     if ((Tablet && !showSteps) || (activeStep == 5 && Tablet)) {
//       return (heightDetails = "592px");
//     }
//     if (Tablet && showSteps) {
//       return (heightDetails = "675px");
//     } else {
//       return (heightDetails = "809px");
//     }
//   };

//   const switchPaddingLefInput = () => {
//     if (ltrTheme.direction === "rtl" && isMobile) {
//       return "25px";
//     }
//   };

//   return (
//     <section
//       className="container-fluid mobile_details_height"
//       style={{ height: heightDetails(), background: !showSteps && "#F1F1F6" }}
//     >
//       <div className="cover">
//         <div className="coverDetails" style={{ height: styleHeight() }}>
//           {" "}
//           {!showSteps ? (
//             <div style={{ position: "relative" }}>
//               <div className="details mobile_details">
//                 <div className="flex_header_details">
//                   <h1 className="h1_mobile">
//                     {" "}
//                     {t("DETAILS_OF_PACKAGE_LINE_1")}{" "}
//                   </h1>{" "}
//                   <p className="p_details_mobile">
//                     {" "}
//                     {t("DETAILS_OF_PACKAGE_LINE_2")}{" "}
//                   </p>{" "}
//                 </div>{" "}
//                 <div className="mobile_width_detailofpackage">
//                   <Stack onFocus={focusClearErrors}>
//                     <div
//                       style={{
//                         marginBottom: isMobile && "0px",
//                         height: isTabletOrMobile && !isMobile && "111px",
//                       }}
//                     >
//                       <LocationSearchInput
//                         errorAutoComplet={errorAutoComplet}
//                         setErrorAutoComplet={setErrorAutoComplet}
//                         exitAddress={exitAddress}
//                         setExitaddress={setExitaddress}
//                         label={t("DETAILS_OF_PACKAGE_INPUT_ADDRESS")}
//                         value={address}
//                         onChange={(address) => setAddress(address)}
//                       />{" "}
//                     </div>
//                     {isTabletOrMobile ? (
//                       <div
//                         className="flex_date_time"
//                         style={{
//                           visibility: isTabletOrMobile ? "visible" : "hidden",
//                           display: isTabletOrMobile ? "block" : "flex",
//                           marginTop: isMobile
//                             ? "60px"
//                             : isTabletOrMobile
//                             ? "0px"
//                             : "60px",
//                           marginBottom: isMobile
//                             ? "45px"
//                             : isTabletOrMobile
//                             ? "0px"
//                             : "65px",
//                         }}
//                       >
//                         <CacheProvider
//                           value={
//                             // old - before calendar
//                             ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr

//                             //ltrTheme.direction === "ltr" ? cacheLtr : cacheRtl
//                           }
//                         >
//                           <CssBaseline /> {/* old - before calendar */}
//                           <TextField
//                             error={!!errorPickUp}
//                             className="textFieldLtr"
//                             label={t("DETAILS_OF_PACKAGE_INPUT_DATE")}
//                             helperText={errorPickUp}
//                             onFocus={checkOnFocus}
//                             type="datetime-local"
//                             inputProps={{
//                               min: now.toISOString().substring(0, 16),
//                               style: {
//                                 textAlign:
//                                   ltrTheme.direction === "ltr"
//                                     ? "right"
//                                     : "left",
//                                 display:
//                                   ltrTheme.direction === "ltr"
//                                     ? "flex"
//                                     : "block",
//                                 flexDirection:
//                                   ltrTheme.direction === "ltr"
//                                     ? "row-reverse"
//                                     : "row",
//                                 height: isMobile
//                                   ? "40px"
//                                   : isTabletOrMobile && "40px",
//                                 alignItems:
//                                   ltrTheme.direction === "rtl" &&
//                                   isMobile &&
//                                   "flex-end",
//                               },
//                             }}
//                             InputProps={{
//                               startAdornment: (
//                                 <InputAdornment
//                                   style={{ cursor: "pointer" }}
//                                   position="start"
//                                 >
//                                   <CalendarMonthIcon />
//                                 </InputAdornment>
//                               ),
//                             }}
//                             value={
//                               time.pickUpTime
//                                 ? time.pickUpTime
//                                 : now.toISOString().substring(0, 16)
//                             }
//                             onChange={changePickUpTime}
//                             sx={{
//                               "& .MuiFormHelperText-root": {
//                                 fontFamily: "heebo",
//                               },
//                               width: isMobile
//                                 ? 300
//                                 : isTabletOrMobile
//                                 ? "100%"
//                                 : "48%",
//                               marginBottom: isMobile
//                                 ? "23px"
//                                 : isTabletOrMobile && "35px",
//                               "& .MuiOutlinedInput-root": {
//                                 fontSize: "16px",
//                                 fontFamily: "heebo",
//                                 paddingLeft: switchPaddingLefInput(),
//                               },
//                               "& fieldset": {
//                                 direction:
//                                   ltrTheme.direction === "rtl" ? "ltr" : "ltr",
//                               },
//                             }}
//                             InputLabelProps={{
//                               style: InputLabelProps,
//                               shrink: true,
//                             }}
//                             // />
//                           />
//                           {/* old - before calendar */}
//                           <TextField
//                             error={!!errorDDline}
//                             helperText={errorDDline ? errorDDline : ""}
//                             onFocus={() => setErrorDDline("")}
//                             label={t("DETAILS_OF_PACKAGE_DDLINE")}
//                             type="datetime-local"
//                             value={
//                               time.ddLine
//                                 ? time.ddLine
//                                 : moment(new Date(time.pickUpTime))
//                                     .add(1, "days")
//                                     .format("YYYY-MM-DD[T]HH:mm")
//                             }
//                             onChange={changeDdLine}
//                             sx={{
//                               "& .MuiFormHelperText-root": {
//                                 fontFamily: "heebo",
//                               },
//                               width: isMobile
//                                 ? 300
//                                 : isTabletOrMobile
//                                 ? "100%"
//                                 : "48%",
//                               marginBottom: isMobile
//                                 ? "23px"
//                                 : isTabletOrMobile && "35px",
//                               "& .MuiOutlinedInput-root": {
//                                 fontSize: "16px",

//                                 fontFamily: "heebo",
//                                 paddingLeft: switchPaddingLefInput(),
//                               },
//                               "& fieldset": {
//                                 direction:
//                                   ltrTheme.direction === "rtl" ? "ltr" : "ltr",
//                               },
//                             }}
//                             InputProps={{
//                               startAdornment: (
//                                 <InputAdornment
//                                   style={{ cursor: "pointer" }}
//                                   position="start"
//                                 >
//                                   <CalendarMonthIcon />
//                                 </InputAdornment>
//                               ),
//                             }}
//                             inputProps={{
//                               min: time.pickUpTime
//                                 ? moment(new Date(time.pickUpTime))
//                                     .add(1, "days")
//                                     .format("YYYY-MM-DD[T]HH:mm")
//                                 : moment(new Date())
//                                     .add(1, "days")
//                                     .format("YYYY-MM-DD[T]HH:mm"),
//                               style: {
//                                 textAlign:
//                                   ltrTheme.direction === "ltr"
//                                     ? "right"
//                                     : "left",
//                                 display:
//                                   ltrTheme.direction === "ltr"
//                                     ? "flex"
//                                     : "block",
//                                 flexDirection:
//                                   ltrTheme.direction === "ltr"
//                                     ? "row-reverse"
//                                     : "row",
//                                 height: isMobile
//                                   ? "40px"
//                                   : isTabletOrMobile && "40px",
//                                 alignItems:
//                                   ltrTheme.direction === "rtl" &&
//                                   isMobile &&
//                                   "flex-end",
//                               },
//                             }}
//                             InputLabelProps={{
//                               style: InputLabelProps,

//                               shrink: true,
//                             }}
//                           />{" "}
//                         </CacheProvider>{" "}
//                       </div>
//                     ) : (
//                       <div
//                         className="flex_date_time"
//                         style={{
//                           visibility: !isTabletOrMobile ? "visible" : "hidden",
//                           display: isTabletOrMobile ? "block" : "flex",
//                           marginTop: isMobile
//                             ? "60px"
//                             : isTabletOrMobile
//                             ? "0px"
//                             : "60px",
//                           marginBottom: isMobile
//                             ? "45px"
//                             : isTabletOrMobile
//                             ? "0px"
//                             : "65px",
//                         }}
//                       >
//                         <CacheProvider
//                           value={
//                             // old - before calendar
//                             ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr

//                             //ltrTheme.direction === "ltr" ? cacheLtr : cacheRtl
//                           }
//                         >
//                           <CssBaseline />{" "}
//                           <LocalizationProvider dateAdapter={AdapterDayjs}>
//                             <DateTimePicker
//                               showToolbar={false}
//                               renderInput={(params) => (
//                                 <TextField {...params} />
//                               )}
//                               error={!!errorPickUp}
//                               label={t("DETAILS_OF_PACKAGE_INPUT_DATE")}
//                               helperText={errorPickUp}
//                               onFocus={checkOnFocus}
//                               type="datetime-local"
//                               InputProps={{
//                                 min: now.toISOString().substring(0, 16),
//                                 style: {
//                                   //marginLeft: !isTabletOrMobile && "10px",

//                                   marginBottom: isMobile
//                                     ? "23px"
//                                     : isTabletOrMobile && "35px",
//                                   textAlign:
//                                     ltrTheme.direction === "ltr"
//                                       ? "right"
//                                       : "left",
//                                   // display:
//                                   //   ltrTheme.direction === "ltr"
//                                   //     ? "flex"
//                                   //     : "block",
//                                   // flexDirection:
//                                   //   ltrTheme.direction === "ltr"
//                                   //     ? "row-reverse"
//                                   //     : "row",
//                                   // height: isMobile
//                                   //   ? "40px"
//                                   //   : isTabletOrMobile && "40px",
//                                   alignItems:
//                                     ltrTheme.direction === "rtl" &&
//                                     isMobile &&
//                                     "flex-end",
//                                 },

//                                 startAdornment: (
//                                   <InputAdornment
//                                     style={{ cursor: "pointer" }}
//                                     position="start"
//                                   >
//                                     {/* <CalendarMonthIcon /> */}
//                                   </InputAdornment>
//                                 ),
//                               }}
//                               value={
//                                 time.pickUpTime
//                                   ? time.pickUpTime
//                                   : now.toISOString().substring(0, 16)
//                               }
//                               onChange={changePickUpTime}
//                               style={{
//                                 "& .MuiOutlinedInput-root": {
//                                   margin: "50px",
//                                   marginBottom: isMobile
//                                     ? "23px"
//                                     : isTabletOrMobile && "35px",
//                                 },
//                               }}
//                               sx={{
//                                 "& .MuiOutlinedInput-root": {
//                                   margin: "50px",
//                                   marginBottom: isMobile
//                                     ? "23px"
//                                     : isTabletOrMobile && "35px",
//                                 },
//                                 "& .MuiFormHelperText-root": {
//                                   fontFamily: "heebo",
//                                 },
//                                 width: isMobile
//                                   ? 300
//                                   : isTabletOrMobile
//                                   ? "100%"
//                                   : "48%",
//                                 marginBottom: isMobile
//                                   ? "23px"
//                                   : isTabletOrMobile && "35px",
//                                 "& fieldset": {
//                                   direction:
//                                     ltrTheme.direction === "rtl"
//                                       ? "ltr"
//                                       : "rtl",
//                                 },
//                               }}
//                               InputLabelProps={{
//                                 style: InputLabelProps,
//                                 shrink: true,
//                               }}
//                             />
//                           </LocalizationProvider>
//                           <div style={{ width: "40px" }}></div>
//                           <LocalizationProvider dateAdapter={AdapterDayjs}>
//                             <DateTimePicker
//                               showToolbar={false}
//                               renderInput={(params) => (
//                                 <TextField {...params} />
//                               )}
//                               error={!!errorDDline}
//                               helperText={errorDDline ? errorDDline : ""}
//                               onFocus={() => setErrorDDline("")}
//                               label={t("DETAILS_OF_PACKAGE_DDLINE")}
//                               type="datetime-local"
//                               value={
//                                 time.ddLine
//                                   ? time.ddLine
//                                   : moment(new Date(time.pickUpTime))
//                                       .add(1, "days")
//                                       .format("YYYY-MM-DD[T]HH:mm")
//                               }
//                               onChange={changeDdLine}
//                               sx={{
//                                 "& .MuiFormHelperText-root": {
//                                   fontFamily: "heebo",
//                                 },
//                                 width: isMobile
//                                   ? 300
//                                   : isTabletOrMobile
//                                   ? "100%"
//                                   : "48%",
//                                 marginBottom: isMobile
//                                   ? "23px"
//                                   : isTabletOrMobile && "35px",
//                                 "& .MuiOutlinedInput-root": {
//                                   fontSize: "16px",
//                                   fontFamily: "heebo",
//                                   paddingLeft: switchPaddingLefInput(),
//                                 },
//                                 "& fieldset": {
//                                   direction:
//                                     ltrTheme.direction === "rtl"
//                                       ? "rtr"
//                                       : "ltr",
//                                 },

//                                 // "& .muirtl-9yjdhh-MuiDialogContent-root:first-of-type":
//                                 //   {
//                                 //     direction: "rtl",
//                                 //   },
//                               }}
//                               InputProps={{
//                                 min: time.pickUpTime
//                                   ? moment(new Date(time.pickUpTime))
//                                       .add(1, "days")
//                                       .format("YYYY-MM-DD[T]HH:mm")
//                                   : moment(new Date())
//                                       .add(1, "days")
//                                       .format("YYYY-MM-DD[T]HH:mm"),
//                                 style: {
//                                   marginBottom: isMobile
//                                     ? "23px"
//                                     : isTabletOrMobile && "35px",

//                                   // margin: "10px",
//                                   textAlign:
//                                     ltrTheme.direction === "ltr"
//                                       ? "right"
//                                       : "left",
//                                   // display:
//                                   //   ltrTheme.direction === "ltr"
//                                   //     ? "flex"
//                                   //     : "block",
//                                   // flexDirection:
//                                   //   ltrTheme.direction === "ltr"
//                                   //     ? "row-reverse"
//                                   //     : "row",
//                                   // height: isMobile
//                                   //   ? "40px"
//                                   //   : isTabletOrMobile && "40px",
//                                   alignItems:
//                                     ltrTheme.direction === "rtl" &&
//                                     isMobile &&
//                                     "flex-end",
//                                 },
//                                 startAdornment: (
//                                   <InputAdornment
//                                     style={{ cursor: "pointer" }}
//                                     position="start"
//                                   >
//                                     {/* <CalendarMonthIcon /> */}
//                                   </InputAdornment>
//                                 ),
//                               }}
//                               InputLabelProps={{
//                                 style: InputLabelProps,
//                                 shrink: true,
//                               }}
//                             />
//                           </LocalizationProvider>
//                           {/* old - before calendar */}
//                           {/* <TextField
//                                                               error={!!errorPickUp}
//                                                               className="textFieldLtr"
//                                                               label={t("DETAILS_OF_PACKAGE_INPUT_DATE")}
//                                                               helperText={errorPickUp}
//                                                               onFocus={checkOnFocus}
//                                                               type="datetime-local"
//                                                               inputProps={{
//                                                                 min: now.toISOString().substring(0, 16),
//                                                                 style: {
//                                                                   textAlign:
//                                                                     ltrTheme.direction === "ltr" ? "right" : "left",
//                                                                   display:
//                                                                     ltrTheme.direction === "ltr" ? "flex" : "block",
//                                                                   flexDirection:
//                                                                     ltrTheme.direction === "ltr"
//                                                                       ? "row-reverse"
//                                                                       : "row",
//                                                                   height: isMobile
//                                                                     ? "40px"
//                                                                     : isTabletOrMobile && "40px",
//                                                                   alignItems:
//                                                                     ltrTheme.direction === "rtl" &&
//                                                                     isMobile &&
//                                                                     "flex-end",
//                                                                 },
//                                                               }}
//                                                               InputProps={{
//                                                                 startAdornment: (
//                                                                   <InputAdornment
//                                                                     style={{ cursor: "pointer" }}
//                                                                     position="start"
//                                                                   >
//                                                                     <CalendarMonthIcon />
//                                                                   </InputAdornment>
//                                                                 ),
//                                                               }}
//                                                               value={
//                                                                 time.pickUpTime
//                                                                   ? time.pickUpTime
//                                                                   : now.toISOString().substring(0, 16)
//                                                               }
//                                                               onChange={changePickUpTime}
//                                                               sx={{
//                                                                 "& .MuiFormHelperText-root": {
//                                                                   fontFamily: "heebo",
//                                                                 },
//                                                                 width: isMobile
//                                                                   ? 300
//                                                                   : isTabletOrMobile
//                                                                   ? "100%"
//                                                                   : "48%",
//                                                                 marginBottom: isMobile
//                                                                   ? "23px"
//                                                                   : isTabletOrMobile && "35px",
//                                                                 "& .MuiOutlinedInput-root": {
//                                                                   fontSize: "16px",
//                                                                   fontFamily: "heebo",
//                                                                   paddingLeft: switchPaddingLefInput(),
//                                                                 },
//                                                                 "& fieldset": {
//                                                                   direction:
//                                                                     ltrTheme.direction === "rtl" ? "ltr" : "ltr",
//                                                                 },
//                                                               }}
//                                                               InputLabelProps={{
//                                                                 style: InputLabelProps,
//                                                                 shrink: true,
//                                                               }}
//                                                               // />
//                                                             /> */}
//                           {/* old - before calendar */}
//                           {/* <TextField
//                                                               error={!!errorDDline}
//                                                               helperText={errorDDline ? errorDDline : ""}
//                                                               onFocus={() => setErrorDDline("")}
//                                                               label={t("DETAILS_OF_PACKAGE_DDLINE")}
//                                                               type="datetime-local"
//                                                               value={
//                                                                 time.ddLine
//                                                                   ? time.ddLine
//                                                                   : moment(new Date(time.pickUpTime))
//                                                                       .add(1, "days")
//                                                                       .format("YYYY-MM-DD[T]HH:mm")
//                                                               }
//                                                               onChange={changeDdLine}
//                                                               sx={{
//                                                                 "& .MuiFormHelperText-root": {
//                                                                   fontFamily: "heebo",
//                                                                 },
//                                                                 width: isMobile
//                                                                   ? 300
//                                                                   : isTabletOrMobile
//                                                                   ? "100%"
//                                                                   : "48%",
//                                                                 marginBottom: isMobile
//                                                                   ? "23px"
//                                                                   : isTabletOrMobile && "35px",
//                                                                 "& .MuiOutlinedInput-root": {
//                                                                   fontSize: "16px",

//                                                                   fontFamily: "heebo",
//                                                                   paddingLeft: switchPaddingLefInput(),
//                                                                 },
//                                                                 "& fieldset": {
//                                                                   direction:
//                                                                     ltrTheme.direction === "rtl" ? "ltr" : "ltr",
//                                                                 },
//                                                               }}
//                                                               InputProps={{
//                                                                 startAdornment: (
//                                                                   <InputAdornment
//                                                                     style={{ cursor: "pointer" }}
//                                                                     position="start"
//                                                                   >
//                                                                     <CalendarMonthIcon />
//                                                                   </InputAdornment>
//                                                                 ),
//                                                               }}
//                                                               inputProps={{
//                                                                 min: time.pickUpTime
//                                                                   ? moment(new Date(time.pickUpTime))
//                                                                       .add(1, "days")
//                                                                       .format("YYYY-MM-DD[T]HH:mm")
//                                                                   : moment(new Date())
//                                                                       .add(1, "days")
//                                                                       .format("YYYY-MM-DD[T]HH:mm"),
//                                                                 style: {
//                                                                   textAlign:
//                                                                     ltrTheme.direction === "ltr" ? "right" : "left",
//                                                                   display:
//                                                                     ltrTheme.direction === "ltr" ? "flex" : "block",
//                                                                   flexDirection:
//                                                                     ltrTheme.direction === "ltr"
//                                                                       ? "row-reverse"
//                                                                       : "row",
//                                                                   height: isMobile
//                                                                     ? "40px"
//                                                                     : isTabletOrMobile && "40px",
//                                                                   alignItems:
//                                                                     ltrTheme.direction === "rtl" &&
//                                                                     isMobile &&
//                                                                     "flex-end",
//                                                                 },
//                                                               }}
//                                                               InputLabelProps={{
//                                                                 style: InputLabelProps,

//                                                                 shrink: true,
//                                                               }}
//                                                             />{" "} */}
//                         </CacheProvider>{" "}
//                       </div>
//                     )}
//                     <Button
//                       onClick={startSteps}
//                       className="marginBtn"
//                       style={{
//                         height: "62px",
//                         margin: "auto",
//                         backgroundColor: "#583DFF",
//                         borderRadius: "4px",
//                       }}
//                       variant="contained"
//                     >
//                       {" "}
//                       <img className="Arrow" src={Arrow} alt="Arrow" />{" "}
//                       {t("DETAILS_OF_PACKAGE_CONTINUE")}{" "}
//                     </Button>{" "}
//                   </Stack>{" "}
//                 </div>{" "}
//               </div>{" "}
//             </div>
//           ) : (
//             <div
//               className="details MultiSteps"
//               style={{ height: isMobile && activeStep == 5 && "700px" }}
//             >
//               <MultiSteps />
//             </div>
//           )}{" "}
//         </div>{" "}
//       </div>{" "}
//     </section>
//   );
// }
