import { Alert,AlertTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from "react-i18next";




export default function ErrorAlert ({ errorBusinnesDetails,setErrorBusinnesDetails,setErrorDetailsPackage,setErrorsCreditCard }) {

const { t, i18n } = useTranslation();
const handelOnFocus =()=>{
    setErrorBusinnesDetails &&   setErrorBusinnesDetails(false);
    setErrorDetailsPackage &&   setErrorDetailsPackage(false);
    setErrorsCreditCard &&      setErrorsCreditCard({error: false, empty: true});
}
    return (
        <div style={{display:"flex",justifyContent:"center"}}>
        <Alert
            sx={{ "& MuiAlert-icon": { marginLeft: "6px", padding: "0",marginBottom:"36px" } }}
            action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        handelOnFocus()
                    }}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            }
            style={{ direction: "rtl", position: "absolute", zIndex: 1000,top:"50%",width: '330px',
            height: '123px',
            background: 'azure',
            display: 'flex',
            flexDirection: 'row-reverse' }}
            severity="error">
            <AlertTitle>{t("ERROR")}</AlertTitle>
            <strong>{errorBusinnesDetails ? t("BUSINESS_DETAILS_ERROR_MESSAGE") : t("BUSINESS_PAYMENT_DETAILS_ERROR_MESSAGE")}</strong>
        </Alert>
</div>
    )
}