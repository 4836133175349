



import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React from "react"
import axios from "axios"
import { useEffect } from "react"
import PaymentForm from "./paymentForm"
import { useState } from "react"

const PUBLIC_KEY = "pk_live_51M1ptiBUOgi0VORXq8ezxi0EdV2GArdMhOkwBEd43S198oJwPvpi0cBr3bgemRylnXcPLAc4NM3GUf3GdtcRSfeX00FF6sRuao"
//const PUBLIC_KEY = "sk_test_51M1ptiBUOgi0VORXLXjZrTAQKy4QWNvvzC6fjtHOZpZ5KaGOQTjkQSEsuGzd5BHJsaH1GR6V6XR8UfsvkwCxXPvo00gsq0DK70"

const stripeTestPromise = loadStripe(PUBLIC_KEY)

export default function StripeContainer({setErrorBusinnesDetails}) {

// 	const secret = async ()=>{
// 	try {
// 		const response = await axios.get("http://localhost:3003/secret");
// 		setClientSecret(response.data)
// 	}
// 	catch (error) {
// 		console.log("Error", error)
// 	}
// 	}
// 	secret().catch(console.error);

// },[])
	
// console.log(clientSecret);
	
	return (
		<Elements stripe={stripeTestPromise}>
			<PaymentForm setErrorBusinnesDetails={setErrorBusinnesDetails}/>
		</Elements>
	)
}