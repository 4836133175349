import { useState, useEffect, createContext, useRef } from "react";
import moment from "moment";

export const DataContext = createContext({});

function ThemeProvider({ children }) {
  const ref = useRef(null);
  const now = new Date();
  now.setMinutes(now.getMinutes() + 1 - now.getTimezoneOffset());
  const ddL = new Date();
  ddL.setHours(ddL.getHours() + 15);

  const [responseGetById, setResponseGetById] = useState(null);
  const [textArea, setTextArea] = useState("");
  const [exitAddress, setExitaddress] = useState({
    cityName: "",
    streetName: "",
    streetNum: "",
    apartment_number: "",
    floor: "",
    entrance: "",
    lat: "",
    lng: "",
  });
  const [exitAddressBusinnes, setExitAddressBusinnes] = useState([]);
  const [extraExitAddressBusinnes, setExtraExitAddressBusinnes] = useState([]);
  const [thirdAddressBusinnes, setThirdAddressBusinnes] = useState([]);
  const [fourthAddressBusinnes, setFourthAddressBusinnes] = useState([]);

  const [validBusinnesCity, setValidBusinnesCity] = useState(true);

  const [errorAutoComplet, setErrorAutoComplet] = useState(false);
  const [errorDDline, setErrorDDline] = useState(false);
  const [errorPickUp, setErrorPickUp] = useState(false);
  const [errorCarType, setErrorCarType] = useState(false);
  const [errorShipmentType, setErrorShipmentType] = useState(false);
  const [errorTypeDescription, setErrorTypeDescription] = useState(false);
  const [errorExpiryDate, setErrorExpiryDate] = useState(false);
  const [address, setAddress] = useState({
    address: "",
  });
  const [extraAddress, setExtraAddress] = useState({
    address: "",
  });
  const [extraAddress3, setExtraAddress3] = useState({
    address: "",
  });
  const [extraAddress4, setExtraAddress4] = useState({
    address: "",
  });
  const [detailsOfPackage, setDetailsOfPackage] = useState([]);
  const [refBackToDetails, setDefBackToDetails] = useState(null);
  const [focus, setFocus] = useState(null);
  const [invalidAddresspopUpMessage, setInvalidAddresspopUpMessage] =
    useState(false);

  const [time, setTime] = useState({
    //ddLine: moment().add(1, "days").format("YYYY-MM-DD[T]HH:mm"),
    //pickUpTime: now.toISOString().substring(0, 16),
    ddLine: "",
    pickUpTime: "",
  });

  const [nameAndPhone, setNameAndPhone] = useState({
    fullName: "",
    phoneNumber: "",
    regulations: true,
    area_code: "+972",
  });
  const [testUser, setTestUser] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [checkboxValue, setCheckboxValue] = useState({
    MotorcycleChecked: "0",
    CarChecked: "0",
    TruckChecked: "0",
  });

  const [typeShippment, setTypeShippment] = useState({
    envelope: "0",
    package: "0",
    other: "0",
  });
  const [getDataFromServer, setGetDataFromServer] = useState({
    track_id: null,
    new_price: 0,
    price_min: 0,
    track_minutes: null,
    track_distance: null,
    count_orders: null,
    currency_symbol: "₪",
    isLoading: null,
  });
  const [allDetailsofBusinnes, setAllDetailsofBusinnes] = useState({
    businnesSpectalization: "",
    fullName: "",
    phone: "",
    smsPhone: "",
    mail: "",
    addressCollection: "",
    cardNumber: "",
    expiryDate: "",
    cvc: "",
    fullNameCardOwner: "",
    id: "",
    stripe_id: ""
  });

  const clone_price_min = Object.assign({}, getDataFromServer);
  const [val, setVal] = useState(0);
  const [isFileXl, setIsFileXl] = useState(false);
  const [openLoginPopup, setOpenLoginPopup] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showSteps, setShowSteps] = useState(false);
  const [openMoreDetailsPopUp, setOpenMoreDetailsPopUp] = useState(false);
  const [loadingTakesTime, setLoadingTakesTime] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [isGuest, setIsGuest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [options, setOptions] = useState("username");
  const [moveToDetailsOfBusiness, setMoveToDetailsOfBusiness] = useState(false);
  const [moveToDetailsOfCreditCard, setMoveToDetailsOfCreditCard] = useState(false);
  const [client_secret, setClientSecret] = useState("");
  const [updateCreditCard, setUpdateCreditCard] = useState(false);
  const [sumitCusId, setSumitCusId] = useState(0);
  const [type, setType] = useState("");
  const [open, setOpen] = useState(true);

  const [isUpdateServer, setIsUpdateServer] = useState(false);
  const [update_credit_card, setUpdate_credit_card] = useState(false);
  const [currentTrack, setCurrentTrack] = useState("");
  const [businessCostumerConnect, setbusinessCostumerConnect] = useState(true);
  const [errorsPhoneName, setErrorsPhoneName] = useState({
    name: false,
    phone: false,
  });

  const [phone_connect, setPhone_connect] = useState({
    phoneNumber: "",
  });

  const [errorsVehicle, setErrorsVehicle] = useState({
    MotorcycleChecked: "0",
    CarChecked: "0",
    TruckChecked: "0",
  });
  const [showDetailsBusiness, setShowDetailsBusiness] = useState(false);
  const [showSearchLocation, setShowSearchLocation] = useState(false);
  const [isSelectShipment, setIsSelectShipment] = useState(false);
  const [isSelectVehicle, setIsSelectVehicle] = useState(false);

  const [errorsTerms, setErrorsTerms] = useState(false);
  const [responseSmsValid, setResponseSmsValid] = useState(null);
  const [paymentDetailsValid, setPaymentDetailsValid] = useState(null);

  const [verificationCheck, setVerificationCheck] = useState(null);
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [idCreditCard, setIdCreditCard] = useState(null);
  const [errorsCreditCard, setErrorsCreditCard] = useState({
    error: false,
    empty: true,
  });
  // const { isSelectShipment, setIsSelectShipment } = useState(false);
  // const { isSelectVehicle, setIsSelectVehicle } = useState(false);

  const switchEditBtnSummary = (key) => {
    switch (key) {
      case "locationAndDateOfPackage":
        setShowSteps(false);

      case "detailsOfPackage":
        setActiveStep((prevActiveStep) => prevActiveStep - 4);
        break;
      case "checked":
        setActiveStep((prevActiveStep) => prevActiveStep - 3);
        break;
      case "payAmount":
        getDataFromServer.isLoading = false;
        setGetDataFromServer({ ...getDataFromServer });
        setActiveStep((prevActiveStep) => prevActiveStep - 2);
        break;
      case "fullNameAndPhone":
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        break;
      default:
        break;
    }
  };

  let components;
  if (nameAndPhone.phoneNumber && nameAndPhone.fullName !== "") {
    const phoneNumber = nameAndPhone.phoneNumber;
    const countryCodeLength = phoneNumber.length - 9;

    components = {
      IDDCC: phoneNumber.substr(0, countryCodeLength),
      NN: phoneNumber.substr(countryCodeLength, phoneNumber.length),
    };
  }
  // console.log(typeShippment);
  return (
    <DataContext.Provider
      value={{
        errorsCreditCard,
        setErrorsCreditCard,
        update_credit_card,
        setUpdate_credit_card,
        exitAddressBusinnes,
        setExitAddressBusinnes,
        type,
        setType,
        typeShippment,
        setTypeShippment,
        client_secret,
        setClientSecret,
        sumitCusId,
        setSumitCusId,
        idCreditCard,
        setIdCreditCard,
        paymentMethodId,
        setPaymentMethodId,
        validToken,
        setValidToken,
        allDetailsofBusinnes,
        setAllDetailsofBusinnes,
        options,
        setOptions,
        showDetailsBusiness,
        setShowDetailsBusiness,
        showSearchLocation,
        setShowSearchLocation,
        open,
        setOpen,
        components,
        showButton,
        setShowButton,
        openMoreDetailsPopUp,
        setOpenMoreDetailsPopUp,
        verificationCheck,
        setVerificationCheck,
        openLoginPopup,
        setOpenLoginPopup,
        responseSmsValid,
        setResponseSmsValid,
        errorsPhoneName,
        setErrorsPhoneName,
        errorsVehicle,
        setErrorsVehicle,
        errorsTerms,
        setErrorsTerms,
        isUpdateServer,
        setIsUpdateServer,
        loadingTakesTime,
        setLoadingTakesTime,
        showInput,
        setShowInput,
        isGuest,
        setIsGuest,
        isLoading,
        setIsLoading,
        responseGetById,
        setResponseGetById,
        textArea,
        setTextArea,
        exitAddress,
        setExitaddress,
        errorAutoComplet,
        setErrorAutoComplet,
        errorCarType,
        setErrorCarType,
        errorShipmentType,
        setErrorShipmentType,
        errorTypeDescription,
        setErrorTypeDescription,
        errorExpiryDate,
        setErrorExpiryDate,
        getDataFromServer,
        setGetDataFromServer,
        errorDDline,
        setErrorDDline,
        errorPickUp,
        setErrorPickUp,
        address,
        setAddress,
        detailsOfPackage,
        setDetailsOfPackage,
        time,
        setTime,
        val,
        setVal,
        ref,
        switchEditBtnSummary,
        nameAndPhone,
        setNameAndPhone,
        checkboxValue,
        setCheckboxValue,
        testUser,
        setTestUser,
        activeStep,
        setActiveStep,
        isFileXl,
        setIsFileXl,
        showSteps,
        setShowSteps,
        refBackToDetails,
        setDefBackToDetails,
        businessCostumerConnect,
        extraAddress,
        setExtraAddress,
        extraExitAddressBusinnes,
        setExtraExitAddressBusinnes,
        invalidAddresspopUpMessage,
        setInvalidAddresspopUpMessage,
        isSelectVehicle,
        setIsSelectVehicle,
        isSelectShipment,
        setIsSelectShipment,
        phone_connect,
        setPhone_connect,
        extraAddress3,
        setExtraAddress3,
        thirdAddressBusinnes,
        setThirdAddressBusinnes,
        extraAddress4,
        setExtraAddress4,
        fourthAddressBusinnes,
        setFourthAddressBusinnes,
        paymentDetailsValid,
        setPaymentDetailsValid,
        moveToDetailsOfBusiness,
        setMoveToDetailsOfBusiness,
        moveToDetailsOfCreditCard,
        setMoveToDetailsOfCreditCard,
        updateCreditCard,
        setUpdateCreditCard,
        currentTrack,
        setCurrentTrack,
        isDisabled,
        setIsDisabled
      }}
    >
      {" "}
      {children}{" "}
    </DataContext.Provider>
  );
}

export default ThemeProvider;
