import pogo from "../images/pogo.png";
// import { Link } from "react-router-dom"
import "./logo/logo.css";
import { Container } from "react-bootstrap";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { DataContext } from "../../../dataContext";
import { useContext } from "react";

export default function Logo({ language }) {
  const { t, i18n } = useTranslation();
  const [num, setNum] = useState(0);

  function randomNumberInRange(min, max) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    setNum(randomNumberInRange(9990, 10010));
    //i18n.changeLanguage(lng);
    // if (document.documentElement.dir === "rtl") {
    //   console.log("lnggg he");
    // } else {
    //   console.log("lnggg en");
    // }
  }, []);

  //const { lng, setLng } = useContext(DataContext);

  return (
    <div className="flex">
      <div className="logoPosition">
        <div className="divHead">
          <div className="flex_numAndString">
            <div className="span_header_logo"> {num} </div>{" "}
            <div className="h1Head"> {t("HEADER_LINE_1")} </div>{" "}
          </div>{" "}
          <div style={{ textAlign: "end" }}>
            <span className="headBold"> {t("HEADER_LINE_2")} </span>{" "}
          </div>{" "}
        </div>{" "}
        {/* <div>
          {" "}
          {t("HEADER_LINE_3")
            .split("\n")
            .map((str, i) => (
              <p key={i} className={`dirHeader`}>
                {" "}
                {str}{" "}
              </p>
            ))}{" "}
        </div>{" "} */}
        <div className={`dirHeader`}>
          {t("HEADER_LINE_3_01")}
          <a
            style={{
              textDecoration: "none",
              color: "black",
            }}
            href="https://connect.getpogo.app/"
          >
            {t("HEADER_LINE_3_02_LINK")}
          </a>
          {t("HEADER_LINE_3_03")}
          <a
            style={{
              textDecoration: "none",
              color: "black",
            }}
            href="https://connect.getpogo.app/"
          >
            {t("HEADER_LINE_3_04_LINK")}
          </a>
          {t("HEADER_LINE_3_05")}
          <a
            style={{
              textDecoration: "none",
              color: "black",
            }}
            href="https://connect.getpogo.app/"
          >
            {t("HEADER_LINE_3_06_LINK")}
          </a>
          {t("HEADER_LINE_3_07")}
        </div>
      </div>{" "}
    </div>
  );
}
